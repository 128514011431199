// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation_main {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
  
  .background1, .background2 {
    position: absolute;
    top: 0;
    width: 50%; /* Половина ширины контейнера */
    height: 100%;
  }
  
  .background1 {
    background-color: white;
    display: flex;
    justify-content: center;
    z-index: 0;
    left: 0;
  }
  
  .background2 {
    background-color: #5584CE;
    justify-content: center;
    display: flex;
    z-index: 0;
    right: 0;
  }
  
  .nav_img {
    width: 587px;
    height: 154px;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.butt {
  height: 40px;
  margin-top: 200px;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background: linear-gradient(135deg, #14ADD6 0%, #384295 100%);
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/navStyle.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,aAAa;EACf;;;EAGA;IACE,kBAAkB;IAClB,MAAM;IACN,UAAU,EAAE,+BAA+B;IAC3C,YAAY;EACd;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,OAAO;EACT;;EAEA;IACE,yBAAyB;IACzB,uBAAuB;IACvB,aAAa;IACb,UAAU;IACV,QAAQ;EACV;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;EAClC;;AAEF;EACE,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,6DAA6D;EAC7D,qBAAqB;AACvB","sourcesContent":[".navigation_main {\r\n    position: relative;\r\n    display: flex;\r\n    flex-direction: row;\r\n    height: 100vh;\r\n  }\r\n  \r\n  \r\n  .background1, .background2 {\r\n    position: absolute;\r\n    top: 0;\r\n    width: 50%; /* Половина ширины контейнера */\r\n    height: 100%;\r\n  }\r\n  \r\n  .background1 {\r\n    background-color: white;\r\n    display: flex;\r\n    justify-content: center;\r\n    z-index: 0;\r\n    left: 0;\r\n  }\r\n  \r\n  .background2 {\r\n    background-color: #5584CE;\r\n    justify-content: center;\r\n    display: flex;\r\n    z-index: 0;\r\n    right: 0;\r\n  }\r\n  \r\n  .nav_img {\r\n    width: 587px;\r\n    height: 154px;\r\n    position: absolute;\r\n    top: 70%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n  }\r\n\r\n.butt {\r\n  height: 40px;\r\n  margin-top: 200px;\r\n  width: 350px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  color: #fff;\r\n  border: none;\r\n  border-radius: 10px;\r\n  cursor: pointer;\r\n  background: linear-gradient(135deg, #14ADD6 0%, #384295 100%);\r\n  text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
