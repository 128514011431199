import { useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from "react";
import useFetchData from "../../functions/UprData";
import Matrix from './Matrix';
import Header from '../Header';
import SideBar from '../SideBar';
import RelatedMatrix from './RelatedMatrix';
import api from '../../api';
import "../../styles/matrixPage.css";
import FullMatrixModal from './FullMatrix';
import ReactLoading from 'react-loading';
import ProtectedContent from '../ProtectedContent';
import { useAuth } from "../../AuthContext";

const MatrixPage = () => {
    const { permissions, user } = useAuth();
    const { month, id } = useParams();
    const [loading, setLoading] = useState(true);
    const [matrix, setMatrix] = useState([]);
    const [project, setProject] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const [loadingRelatedMatrix, setLoadingRelatedMatrix] = useState(false)

    const fetchMatrix = useFetchData(`/api/v1/salary/matrix/?month=${month}&project=${id}`, setMatrix);
    const fetchProject = useFetchData(`/api/v1/organization/projects/${id}/`, setProject)

    const [selectedItemData, setSelectedItemData] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchMatrix(),
                fetchProject()
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    const showModal = () => {
        setIsOpen(true);
      };
    
    const hideModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        fetchData();
    }, [month, id]);

    
    const handleItemClick = useCallback(async (item) => {
        try {
            setLoadingRelatedMatrix(true);
            const response = await api.get(`/api/v1/salary/matrix-individ/?individ=${item.individual.id}&month=${month}`);
            setSelectedItemData(response.data);
            setSelectedItemId(item.id);
        } catch (error) {
            console.error('Error fetching individual matrix data', error);
        } finally {
            setLoadingRelatedMatrix(false);
        }
    }, [month, id]);

    const handleDeleteItem = async (itemId) => {
        try {
            if (!permissions.includes('salary.delete_salarymatrix') && !user.is_superuser) {
                return;
            }
            await api.delete(`/api/v1/salary/matrix/${itemId}/delete`);
            setMatrix(prevMatrix => prevMatrix.filter(item => item.id !== itemId));
        } catch (error) {
            console.error('Error deleting item', error);
        }
    };

    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
            </div>);
    }

    return(
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="salary-management container-fluid">
                        <div className="row">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <div>
                                <h5>{project ? `Проект: ${project.name}` : 'Проект'}</h5>
                                <h5>{month ? `Месяц: ${month}` : ''}</h5>
                                </div>
                                <div>
                                    <button className="btn btn-primary">Обновить</button>
                                </div>
                            </div>
                            <div className="col-md text-center" style={{height: '65vh'}}>
                                <div>
                                    <Matrix 
                                        matrix={matrix}
                                        setMatrix={setMatrix} 
                                        onItemClick={handleItemClick} 
                                        onItemDelete={handleDeleteItem}
                                        month={month}
                                        project={id}
                                        selectedItemId={selectedItemId}/>
                                    {loading && <div className="text-center">Loading more...</div>}
                                </div>
                            </div>
                            <div className="col-md d-flex flex-column" style={{height: '65vh'}}>
                                <div>
                                    <RelatedMatrix person_matrix={selectedItemData.filter(dataItem => dataItem.project.id !== id)} />
                                    {loadingRelatedMatrix && <div className="d-flex align-items-center justify-content-center">
                                            <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                        </div>}
                                </div>
                                <div className='info-button mt-auto'>
                                    <button className='btn btn-primary' onClick={showModal}>Полная информация</button>
                                </div>
                                <FullMatrixModal 
                                    matrix={selectedItemData}
                                    hideModal={hideModal}
                                    isOpen={isOpen}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MatrixPage;