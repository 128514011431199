import React from 'react';
import { Route, Routes, Navigate} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import RegistrationForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import PaymentEdit from './components/payments/PaymentEdit';
import BankAccountsEdit from './components/bankaccounts/BankAccountsEdit';
import PaymentManagement from './components/payments/PaymentManagement';
import CreatePayment from './components/payments/CreatePayment';
import BankAccounts from './components/bankaccounts/BankAccounts';
import CreateBankAccount from './components/bankaccounts/CreateBankAcc';
import CaptionManagement from './components/captions/CaptionManagement';
import CaptionEdit from './components/captions/CaptionEdit';
import CaptionCreate from './components/captions/CaptionCreate';
import Finmodel from './components/Finmodel';
import SalaryManagement from './components/salaries/SalaryManagement.jsx';
import ProjectMonthPage from './components/salaries/ProjectMonthPage.jsx';
import MatrixPage from './components/salaries/MatrixPage.jsx';
import Counterparties from './components/Counterparties/Counterparties.jsx';
import CounterpartiesEdit from './components/Counterparties/CounterpartiesEdit.jsx';
import CounterpartyCreate from './components/Counterparties/CounterpartyCreate.jsx'
import Projects from './components/projects/Projects.jsx'
import Employees from './components/employees/Employee.jsx'
import ProjectEdit from './components/projects/ProjectEdit.jsx'
import ProjectCreate from './components/projects/ProjectCreate.jsx'
import CreateUserPage from './components/CreateUserPage.jsx';
import CreateGroup from './components/roles/CreateGroup.jsx';
import EditGroup from './components/roles/EditGroup.jsx';
import OrganizationPage from './components/organizations/OrganizationPage.jsx';
import BudgetsPage from './components/budgets/BudgetsPage.jsx';
import UserEditPage from './components/UserEditPage.jsx';
import BudgetPlanPage from './components/budgets/BudgetPlanPage.jsx';
import { useAuth } from './AuthContext';
import ProtectedContent from './components/ProtectedContent.jsx';
import Nomenclature from './components/payments/nomenclature.jsx';
import DocumentTypes from './components/documentTypes/DocumentTypes.jsx';
import OperationTypes from './components/operationTypes/OperationTypes.jsx';

function Logout() {
  const { logout } = useAuth();
  return <Navigate to="/login"/>
}

function RegisterAndLogout() {
  localStorage.clear()
  return <RegistrationForm />
}

class App extends React.Component {
  render(){

  
  return (
        <div>
            <Routes>
              <Route path='/' element={<ProtectedRoute><Home /></ProtectedRoute>}></Route>
              <Route path='/navigation' element={<Navigation/>}></Route>
              <Route path='/logout' element={<Logout/>}></Route>
              <Route path="/login" element={<LoginForm/>}></Route>
              <Route path='*' element={<NotFound/>}></Route>
              {/* <Route path="/register" element={<RegistrationForm/>}></Route> */}
              <Route path="/edit-payment/:id" 
                    element={
                      <ProtectedContent perms={"payment.change_payment"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <PaymentEdit/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/payment-registry" 
                    element={
                      <ProtectedContent perms={"payment.view_payment"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <PaymentManagement/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>            
              <Route path="/create-payment" 
                    element={
                      <ProtectedContent perms={"payment.add_payment"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <CreatePayment/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/bankacc" 
                    element={
                      <ProtectedContent perms={"bank.view_bankaccount"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <BankAccounts/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path='/captions' 
                    element={
                      <ProtectedContent perms={"payment.view_caption"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <CaptionManagement/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>             
              <Route path='/finmodel' element={<ProtectedRoute><Finmodel/></ProtectedRoute>}></Route>      
              <Route path="/salaries/:month" 
                    element={
                      <ProtectedContent perms={"salary.view_salarymatrix"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <ProjectMonthPage/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>   
              <Route path="/salaries" 
                    element={
                      <ProtectedContent perms={"salary.view_salarymatrix"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <SalaryManagement/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>    
              <Route path="/salaries/:month/:id" 
                    element={
                      <ProtectedContent perms={"salary.view_salarymatrix"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <MatrixPage/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/counterparty" 
                    element={
                      <ProtectedContent perms={"payment.view_counterparty"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <Counterparties/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/projects" 
                    element={
                      <ProtectedContent perms={"organization.view_project"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <Projects/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/employees" 
                    element={
                      <ProtectedContent perms={"payment.view_individual"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <Employees/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/create-user" 
                    element={
                      <ProtectedContent perms={"users.add_user"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <CreateUserPage/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/edit-user/:id/" 
                    element={
                      <ProtectedContent perms={"users.change_user"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <UserEditPage/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/create-group" 
                    element={
                      <ProtectedContent perms={"auth.add_group"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <CreateGroup/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/edit-group/:id/" 
                    element={
                      <ProtectedContent perms={"auth.change_group"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <EditGroup/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/organizations" 
                    element={
                      <ProtectedContent perms={"organization.view_company"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <OrganizationPage/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/budgets" 
                    element={
                      <ProtectedContent perms={"budget.view_budget"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <BudgetsPage/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/budgeting" 
                    element={
                      <ProtectedContent perms={"budget.view_budget"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <BudgetPlanPage/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/nomenclature" 
                    element={
                      // <ProtectedContent perms={"payment.nomenclature"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <Nomenclature/>
                        </ProtectedRoute>
                      // </ProtectedContent>}>
                    }>
              </Route>
              <Route path="/documentTypes" 
                    element={
                      <ProtectedContent perms={"payment.view_documenttypes"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <DocumentTypes/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              <Route path="/operationTypes" 
                    element={
                      <ProtectedContent perms={"payment.view_operationtypes"} alt={<>Нет доступа</>}>
                        <ProtectedRoute>
                          <OperationTypes/>
                        </ProtectedRoute>
                      </ProtectedContent>}>
              </Route>
              {/* <Route path='/edit-payment/:id' element={<ProtectedRoute><PaymentEdit/></ProtectedRoute>}></Route> */}
              {/* <Route path="/organizations" element={<ProtectedRoute><OrganizationPage/></ProtectedRoute>}></Route> */}
              {/* <Route path="/budgets" element={<ProtectedRoute><BudgetsPage/></ProtectedRoute>}></Route> */}
              {/* <Route path="/budgeting" element={<ProtectedRoute><BudgetPlanPage/></ProtectedRoute>}></Route> */}
              {/* <Route path="/create-group" element={<ProtectedRoute><CreateGroup/></ProtectedRoute>}></Route> */}
              {/* <Route path="/edit-group/:id/" element={<ProtectedRoute><EditGroup/></ProtectedRoute>}></Route> */}
              {/* <Route path='/edit-bankacc/:id' element={<ProtectedRoute><BankAccountsEdit/></ProtectedRoute>}></Route> */}
              {/* <Route path="/projects-edit/:id" element={<ProtectedRoute><ProjectEdit/></ProtectedRoute>}></Route>
              <Route path="/create-project" element={<ProtectedRoute><ProjectCreate/></ProtectedRoute>}></Route> */}
              {/* <Route path="/edit-counterparty/:id" element={<ProtectedRoute><CounterpartiesEdit/></ProtectedRoute>}></Route>
              <Route path="/create-counterparty" element={<ProtectedRoute><CounterpartyCreate/></ProtectedRoute>}></Route> */}
              {/* <Route path='/caption-edit/:id' element={<ProtectedRoute><CaptionEdit/></ProtectedRoute>}></Route>
              <Route path='/create-caption' element={<ProtectedRoute><CaptionCreate/></ProtectedRoute>}></Route>     */}
        </Routes>
        </div>
        
  );
}
};
export default App;
