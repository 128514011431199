import { Link } from 'react-router-dom';
import ProtectedContent from './ProtectedContent';

const SideBar = () => {
    return (
        <>
            <div className="col-auto col-md-3 col-xl-3 px-0 bg-white">
                    <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-black min-vh-100">
                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                            <li className="nav-item">
                                <Link to='/' className="nav-link px-0 align-middle text-black">
                                    <i className="fs-5 bi-house"></i> <span className="ms-1 d-sm-inline text-black">Рабочий стол</span>
                                </Link>
                            </li>
                            <ProtectedContent perms={['payment.view_payment']}>
                                <li>
                                    <Link to='/payment-registry' className="nav-link px-0 align-middle text-black">
                                        <i className="fs-5 bi-people"></i> <span className="ms-1 d-sm-inline">Платежи</span>
                                    </Link>
                                </li>
                            </ProtectedContent>
                            <ProtectedContent perms={['salary.view_salarymatrix']}>
                                <li>
                                    <Link to='/salaries' className="nav-link px-0 align-middle text-black">
                                        <i className="fs-5 bi-cash-stack"></i> <span className="ms-1 d-sm-inline">Заработные платы</span>
                                    </Link>
                                </li>
                            </ProtectedContent>
                            <ProtectedContent perms={['budget.view_budget']}>
                                <li>
                                    <Link to='/budgeting' className="nav-link px-0 align-middle text-black">
                                        <i className="fs-5 bi-cash-stack"></i> <span className="ms-1 d-sm-inline">Бюджетирование</span>
                                    </Link>
                                </li>
                            </ProtectedContent>
                            <li>
                                <a href="#submenu3" data-bs-toggle="collapse" className="nav-link px-0 align-middle text-black">
                                    <i className="fs-4 bi-grid"></i> <span className="ms-1 d-sm-inline">Справочники</span> </a>
                                <ul className="collapse nav flex-column ms-1" id="submenu3" data-bs-parent="#menu">
                                <ProtectedContent perms={['bank.view_bankaccount']}>
                                    <li>
                                    <Link to='/bankacc' className="nav-link px-0 align-middle text-black">
                                        <span className="ms-1 d-sm-inline">Банковские счета</span>
                                    </Link>
                                    </li>
                                </ProtectedContent>
                                <ProtectedContent perms={['payment.view_caption']}>
                                    <li>
                                    <Link to='/captions' className="nav-link px-0 align-middle text-black">
                                        <span className="ms-1 d-sm-inline">Статьи</span>
                                    </Link>
                                    </li>
                                </ProtectedContent>
                                <ProtectedContent perms={['payment.view_counterparty']}>
                                    <li>
                                    <Link to='/counterparty' className="nav-link px-0 align-middle text-black">
                                        <span className="ms-1 d-sm-inline">Контрагенты</span>
                                    </Link>
                                    </li>
                                </ProtectedContent>
                                <ProtectedContent perms={['organization.view_project']}>
                                    <li>
                                    <Link to='/projects' className="nav-link px-0 align-middle text-black">
                                        <span className="ms-1 d-sm-inline">Проекты</span>
                                    </Link>
                                    </li>
                                </ProtectedContent>
                                <ProtectedContent perms={['payment.view_individual']}>
                                    <li>
                                    <Link to='/employees' className="nav-link px-0 align-middle text-black">
                                        <span className="ms-1 d-sm-inline">Физические лица</span>
                                    </Link>
                                    </li>
                                </ProtectedContent>
                                <ProtectedContent perms={['organization.view_company']}>
                                    <li>
                                    <Link to='/organizations' className="nav-link px-0 align-middle text-black">
                                        <span className="ms-1 d-sm-inline">Организации</span>
                                    </Link>
                                    </li>
                                </ProtectedContent>
                                <ProtectedContent perms={['budget.view_budget']}>
                                    <li>
                                    <Link to='/budgets' className="nav-link px-0 align-middle text-black">
                                        <span className="ms-1 d-sm-inline">Бюджеты</span>
                                    </Link>
                                    </li>
                                </ProtectedContent>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
        </>
    );
}

export default SideBar;