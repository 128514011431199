// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.deciphercontent {
    display: block;
    margin-block: 15px;
}

.deciphers {
    display: block;
    width: 55vw;
}

.decipher.row {
    background-color: #D9D9D97D;
    border-radius: 10px;
    margin: 10px;
}

.guidecountparties{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/createPayment.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\r\n.deciphercontent {\r\n    display: block;\r\n    margin-block: 15px;\r\n}\r\n\r\n.deciphers {\r\n    display: block;\r\n    width: 55vw;\r\n}\r\n\r\n.decipher.row {\r\n    background-color: #D9D9D97D;\r\n    border-radius: 10px;\r\n    margin: 10px;\r\n}\r\n\r\n.guidecountparties{\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
