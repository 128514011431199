import { useNavigate } from "react-router-dom";

const MonthPercentTable = ({ monthPercents }) => {

    const navigate = useNavigate()

    const handleRowClick = (monthPercent) => {
        navigate(`/salaries/${monthPercent.month}`)
    }
 
    return (
        <table className="table table-striped">
            <thead className="table-light">
                <tr>
                    <th>Месяц</th>
                    <th>Разнесено сотрудников</th>
                    <th>Дата платежа</th>
                </tr>
            </thead>
            <tbody>
                {monthPercents.map((monthPercent) => (
                    <tr key={monthPercent.month} onDoubleClick={() => handleRowClick(monthPercent)}>
                        <td>{monthPercent.month_str}</td>
                        <td>{monthPercent.month_fill_percent}</td>
                        <td>{monthPercent.formatted_date}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}


export default MonthPercentTable;