

const RelatedMatrix = ({person_matrix}) => {

    return (
        <>
            <table className="table"> 
                <thead className="table-light">
                    <tr>
                        <th>Проект</th>
                        <th>Процент, %</th>
                    </tr>
                </thead>
                <tbody>
                    {person_matrix.map((item) => (
                        <tr key={item.id}>
                            <td>{item.project ? item.project.name : ''}</td>
                            <td>{item.percent}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default RelatedMatrix;