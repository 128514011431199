// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_page_con {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  
  
  .login_container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .login_header {
    display: flex;
    align-items: center;
    gap: 258px;
    margin-bottom: 30px;
  }
  
  .login_header img {
    width: 200px;
    height: 40px;
  }
  
  .login_header a {
    text-decoration: none;
    color: #007bff;
  }
  
  
  
  .login_main h2 {
    margin-bottom: 20px;
    font-family: 'Segoe UI', Verdana, sans-serif;
    font-size: 28px;
    line-height: 38px;
  }
  
  .login_main form {
    display: flex;
    flex-direction: column;
  }
  
  .login_main button {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background: linear-gradient(135deg, #14ADD6 0%, #384295 100%);
  }
  
  .image_container {
    display: flex;
    background-color: #5584CE;
    flex: 1 1;
    justify-content: center;
    align-items: center;
  }`, "",{"version":3,"sources":["webpack://./src/styles/loginStyle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;EACf;;;EAGA;IACE,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;;;EAIA;IACE,mBAAmB;IACnB,4CAA4C;IAC5C,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,6DAA6D;EAC/D;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,SAAO;IACP,uBAAuB;IACvB,mBAAmB;EACrB","sourcesContent":[".login_page_con {\r\n    display: flex;\r\n    flex-direction: row;\r\n    height: 100vh;\r\n  }\r\n  \r\n  \r\n  .login_container {\r\n    flex: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  \r\n  .login_header {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 258px;\r\n    margin-bottom: 30px;\r\n  }\r\n  \r\n  .login_header img {\r\n    width: 200px;\r\n    height: 40px;\r\n  }\r\n  \r\n  .login_header a {\r\n    text-decoration: none;\r\n    color: #007bff;\r\n  }\r\n  \r\n  \r\n  \r\n  .login_main h2 {\r\n    margin-bottom: 20px;\r\n    font-family: 'Segoe UI', Verdana, sans-serif;\r\n    font-size: 28px;\r\n    line-height: 38px;\r\n  }\r\n  \r\n  .login_main form {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  .login_main button {\r\n    padding: 10px 20px;\r\n    color: #fff;\r\n    border: none;\r\n    border-radius: 10px;\r\n    cursor: pointer;\r\n    background: linear-gradient(135deg, #14ADD6 0%, #384295 100%);\r\n  }\r\n  \r\n  .image_container {\r\n    display: flex;\r\n    background-color: #5584CE;\r\n    flex: 1;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
