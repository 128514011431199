import { Modal } from 'react-bootstrap';
import ProtectedContent from '../ProtectedContent';

const FullMatrixModal = ({matrix, isOpen, hideModal}) => {

    return (
        <>
            <Modal show={isOpen} onHide={hideModal} dialogClassName={"modal-dialog-centered modal-dialog-scrollable"}>
                <Modal.Header className='d-flex'>
                    <Modal.Title>Полная информация</Modal.Title>
                    <i 
                        className='fs-6 ms-auto bi bi-x-circle'
                        onClick={hideModal}
                        ></i>
                </Modal.Header>
                <Modal.Body>
                <>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>ФИО</th>
                                <th>Проект</th>
                                <th>Процент, %</th>
                                <th>Источник выплат</th>
                                <ProtectedContent perms={'salary.view_salary_salarymatrix'}><th>Оклад</th></ProtectedContent>
                            </tr>
                        </thead>
                        <tbody>
                            {matrix.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.individual ? item.individual.name : ''}</td>
                                    <td>{item.project ? item.project.name : ''}</td>
                                    <td>{item.percent}</td>
                                    <td>{item.organization ? item.organization.name : ''}</td>
                                    <ProtectedContent perms={'salary.view_salary_salarymatrix'}><td>{item?.salary_sum}</td></ProtectedContent>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FullMatrixModal;