import React, { useState, useEffect, useRef, useCallback } from 'react';
import api from '../../api';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import ProtectedContent from '../ProtectedContent';
import { useAuth } from "../../AuthContext";
import SearchableSelect from '../payments/SearchableSelect'; 


const DocumentTypes = () => {
    const navigate = useNavigate()
    const { permissions, user } = useAuth();
    const [documenttypes, setDocumentTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedDocumentTypes, setCheckedDocumentTypes] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false); //yes
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);  //no
    const [startDateFilterOne, setStartOneDateFilter] = useState('');
    const searchIDofelem = location.search.replace("?","");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = {
        name: '',
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const fetchDocumentTypes = async () => {
        setLoading(true);
        try {
            console.log(location)
            // if (searchIDofelem){
            //     const response = await api.post('/api/v1/bills/documenttypefilter/', {
            //         id:searchIDofelem,
            //         is_active : [true,false]
            //     });
            //     setDocumentTypes(response.data);
            // }else{
                const response = await api.get(`api/v1/bills/document-types/?limit=${limit}&offset=${offset}`);
             setDocumentTypes(prevDocumentTypes => {
                const newDocumentTypes = response.data.results.filter(newDocumentTypes => 
                    !prevDocumentTypes.some(prevDocumentTypes => prevDocumentTypes.id === newDocumentTypes.id)
                );
                return [...prevDocumentTypes, ...newDocumentTypes];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
            // }
           
        } catch (error) {
            console.error('Error fetching bank accounts', error);
        } finally {
            setLoading(false);
        }
    };
    
    
    useEffect(() => {
        setDocumentTypes([]); // Clear existing documenttypes
        setOffset(0); // Reset offset
        setHasMore(true);
        fetchDocumentTypes();
    }, [location]);

    useEffect(() => {
        const loadMore = async () => {
            if (hasMore && !loading && offset > 0) {
                setLoading(true);
                try {
                    await fetchDocumentTypes();
                } catch (error) {
                    console.error('Error fetching data', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        
        loadMore();
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);
    useEffect(() => {
        // Вызываем фильтрацию каждый раз, когда изменяется состояние.
        handleSearch();
    }, [ startDateFilterOne,nameFilter,archivedFilterNotArchive,archivedFilterActive]); 
    

    // useEffect(() => {
    //     const fetchDocumentTypesById = async () => {
    //         if (searchIDofelem) {
    //             try {
    //                 const response = await api.post('/api/v1/bills/documenttypefilter/', {
    //                     id:searchIDofelem,
    //                     is_active : [true,false]
    //                 });
    //                 if (response.data.length > 0) {
    //                     setNameFilter(response.data[0].name);
    //                     setDocumentTypes(response.data);
    //                 }
    //             } catch (error) {
    //                 console.error('Error fetching caption by ID', error);
    //             }
    //         } else {
    //             setNameFilter('');
    //             setDocumentTypes([]);
    //             setOffset(0);
    //             setHasMore(true);
    //             fetchDocumentTypes();
    //         }
    //     };

    //     fetchDocumentTypesById();
    // }, [searchIDofelem]);


    const handleUpdateDocumentTypes = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedDocumentTypes.map(id => 
                api.patch(`api/v1/bills/document-types/${id}/update`, { is_active: isActive })
            ));
            // Refresh the list after updating
            setDocumentTypes([]);
            fetchDocumentTypes();
            // setCompanies([]);
            setOffset(0);
            setHasMore(true);
            setCheckedDocumentTypes([])
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} DocumentTypes`, error);
        } finally {
            setLoading(false);
        }
    };
    const handleCheckboxChange = (documenttypeId) => {
        setCheckedDocumentTypes(prevdocumenttypes => {
            if (prevdocumenttypes.includes(documenttypeId)) {
                return prevdocumenttypes.filter(id => id !== documenttypeId);
            } else {
                return [...prevdocumenttypes, documenttypeId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const alldocumenttypes = documenttypes.map(documenttype => documenttype.id);
            setCheckedDocumentTypes(alldocumenttypes);
        } else {
            setCheckedDocumentTypes([]);
        }
    };

    const allChecked = documenttypes.length > 0 && documenttypes.every(documenttype => checkedDocumentTypes.includes(documenttype.id));

    const handleReset = () => {
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();

    };
    const handleSearch = async () => {
        try {
            const response = await api.post('/api/v1/bills/document-typesfilter/', {
                name: nameFilter.toLowerCase(), 
                is_active : [!archivedFilterNotArchive,archivedFilterActive],
                startDateOne:startDateFilterOne,
               
            });
            console.log('DocumentTypes fetched:', response.data); 
            setDocumentTypes(response.data); 
        } catch (error) {
            console.error('Error fetching DocumentTypes:', error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ['name'];
        const errors = requiredFields.reduce((acc, key) => {
            if (!formData[key]) {
                if (key === 'company') {
                    acc[key] = 'Выберите значение';
                } else {
                    acc[key] = 'Заполните поле';
                }
            }
            return acc;
        }, {});
      
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
          }
        setLoading(true);
        try {
            if (isEditing) {
                const response = await api.patch(`/api/v1/bills/document-types/${formData.id}/update/`, formData);
                console.log('Update Response:', response.data);
            } else {
                const response = await api.post('api/v1/bills/document-types-create/', formData);
                console.log('Create Response:', response.data);
            }
            setDocumentTypes([]);
            setOffset(0);
            handleCloseModal();
            fetchDocumentTypes();
        } catch (error) {
            alert('Редактирование данного типа документа недоступно.');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };
    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };
    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
    
        return `${day}.${month}.${year}`;
    };
    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const handleRowClick = (documenttype) => {
        if (!permissions.includes('payment.change_documenttype') && !user.is_superuser) {
            return;
        }
        setIsEditing(true);
        setFormData({
            id: documenttype.id,
            name: documenttype.name,
            origin: documenttype.origin,
  });
        setShowModal(true);
    };
    

    return (
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Типы документа</h3></div>
                                    <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование типа документа</label>
                                            <input
                                                type="text"
                                                placeholder="Введите тип документа"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className='col'>
                                                <label className='form-label'>Дата создания</label>
                                                    <div className="d-flex">
                                                        <input
                                                            type="date"
                                                            value={startDateFilterOne}
                                                            onChange={e => setStartOneDateFilter(e.target.value)} // Только обновляем состояние
                                                            className="form-control form-control-sm"
                                                        />
                                                    </div>
                                                </div>
                                        <div className="col">
                                            <label>Архивный</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={archivedFilterNotArchive}
                                                    onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={archivedFilterActive}
                                                    onChange={e => setArchivedFilterActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            {/* <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button> */}
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <ProtectedContent perms={'payment.add_documentType'}>
                                            <button className="btn btn-primary me-2" onClick={() => setShowModal(true)}>
                                                Добавить
                                            </button>
                                        </ProtectedContent>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '55vh', overflowY: 'scroll' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input 
                                                    type="checkbox" 
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th>Наименование типа документа</th>
                                            <th>Дата создания</th>
                                            <th>Архивный</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {documenttypes.map((documenttype) => (
                                            <tr key={documenttype.name} onDoubleClick={() => handleRowClick(documenttype)}>
                                                <td>
                                                    <input 
                                                        type="checkbox"
                                                        checked={checkedDocumentTypes.includes(documenttype.id)}
                                                        onChange={() => handleCheckboxChange(documenttype.id)}
                                                    />
                                                </td>
                                                <td style={{ 
                                                    width: '300px', 
                                                    maxWidth: '300px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>
                                                    {documenttype.name}
                                                </td>
                                                <td style={{ 
                                                    width: '300px', 
                                                    maxWidth: '300px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{formatDate(documenttype.created)}</td>
                                                <td>{documenttype.is_active ? 'Нет' : 'Да'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-center position-relative">
                            <h5 className="modal-title">
                                {isEditing ? 'Редактирование типа документа' : 'Создание типа документа'}
                            </h5>
                        </div>
                            <div className="modal-body">
                                <form className="bankaccount-form" onSubmit={handleSubmit}>
                                    <div className='form_input'>
                                            <div className="row g-1">
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Наименование типа документа*</label>
                                                    <input 
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                        type="text" 
                                                        name="name" 
                                                        value={formData.name}
                                                        placeholder='Введите наименование' 
                                                        onChange={handleChange}
                                                        disabled={formData.origin !== 'webupr'}
                                                    />
                                                    {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                                                    {formData.origin !== 'webupr' && (
                                                    <p style={{color:'red'}}>Данный тип документа запрещено редактировать, т.к. это типовой параметр</p>
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </form>
                                </div>
                                <div className="modal-footer d-flex justify-content-center position-relative">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    Закрыть
                                </button>
                                <button className="btn btn-primary" onClick={handleSubmit}>
                                {isEditing ? 'Сохранить' : 'Создать'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentTypes;
