import { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import api from "../../api";

export const CaptionForm = ({ captions, setCaptions, param, index, handleSelectChange, caption_types, parentCaption }) => {
  const [showModal, setShowModal] = useState(false);
  const [newCaption, setNewCaption] = useState({
    name: '',
    description: '',
    parent_caption: parentCaption || '',
    type_of_caption: '',
  });
  const [selectedCaption, setSelectedCaption] = useState({
    value: param?.id,
    label: param?.name
  });
  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
    setNewCaption({
      name: '',
      description: '',
      parent_caption: '',
      type_of_caption: '',
    });
  };

  const handleModalSave = async () => {
    try {
      const response = await api.post('/api/v1/bills/caption-create/', newCaption);
      if (response.status === 201) {
        const newCaptionData = response.data;
        setCaptions([...captions, newCaptionData]);
        handleModalClose();
      } else {
        console.error('Failed to create new caption', response);
      }
    } catch (error) {
      console.error('An error occurred while creating new caption', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCaption({
      ...newCaption,
      [name]: value
    });
  };

  const captionSearchApiCall = (inputValue) => {
    // Если есть parentCaption, используем POST-запрос к фильтру
    if (parentCaption) {
      return api
        .post('/api/v1/bills/captionfilter/', {
          name: inputValue.toLowerCase(),
          parent_caption: parentCaption,
          is_active: [true]
        })
        .then((response) => {
          console.log('Captions fetched:', response.data);
          const options = response.data.map((caption) => ({
            value: caption.id,
            label: caption.name,
            parent_id: caption.parent_caption,
          }));
    
          return [{ value: "create-new", label: "Создать новую статью..." }, ...options];
        })
        .catch((error) => {
          console.error("Error fetching search results:", error);
          return [];
        });
    }
    
    // Если нет parentCaption, используем GET-запрос как раньше
    return api
      .get(`/api/v1/bills/caption/search/?search=${inputValue}`)
      .then((response) => {
        const options = response.data.map((caption) => ({
          value: caption.id,
          label: caption.name,
        }));
    
        return [{ value: "create-new", label: "Создать новую статью..." }, ...options];
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
        return [];
      });
  };

  const handleSelectChangeInternal = (selectedOption) => {
    if (!selectedOption) {
      setSelectedCaption(null);
      handleSelectChange(index, { name: "caption", value: null });
      return;
    }
  
    if (selectedOption.value === "create-new") {
      setShowModal(true);
    } else {
      setSelectedCaption(selectedOption);
      handleSelectChange(index, { name: "caption", value: selectedOption.value });
    }
  };
  return (
    <>
      <div className="col-md-4">
        <label className="form-label">Статья</label>
        <AsyncSelect
          loadOptions={captionSearchApiCall}
          defaultOptions
          value={selectedCaption}
          onChange={handleSelectChangeInternal}
          placeholder="Выберите статью"
          isClearable
          isLoading={loading}
          styles={{
            control: (provided) => ({
              ...provided,
              fontSize: '15.8px',
            }),
            option: (provided) => ({
              ...provided,
              color: 'black',
              fontSize: '15.8px',
            }),
            singleValue: (provided) => ({
              ...provided,
              color: 'black',
              fontSize: '15.8px',
            }),
            placeholder: (provided) => ({
              ...provided,
              color: 'black',
              fontSize: '15.8px',
            }),
            input: (provided) => ({
              ...provided,
              color: 'black',
              fontSize: '15.8px',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              fontSize: '15.8px',
            }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: '200px',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              neutral80: 'black',
              neutral90: 'black',
            },
          })}
        />
      </div>


      <Modal show={showModal} onHide={handleModalClose} dialogClassName={"modal-dialog-centered modal-dialog-scrollable"}>
        <Modal.Header closeButton>
          <Modal.Title>Создать новую статью</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewCaption">
              <Form.Label>Название новой статьи</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={newCaption.name}
                onChange={handleChange}
                placeholder="Введите название"
              />
            </Form.Group>
            <Form.Group controlId="formCaptionType">
              <Form.Label>Тип статьи</Form.Label>
              <Form.Select
                name="type_of_caption"
                value={newCaption.type_of_caption || ''}
                onChange={handleChange}
              >
                <option value="">
                  {newCaption.type_of_caption ? caption_types.find(caption_type => caption_type.id === newCaption.type_of_caption)?.name : 'Выберите тип статьи...'}
                </option>
                {caption_types.map((caption_type) => (
                  <option key={caption_type.id} value={caption_type.id}>
                    {caption_type.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formParentCaption">
              <Form.Label>Родительская статья</Form.Label>
              <Form.Select
                name="parent_caption"
                value={newCaption.parent_caption || parentCaption || ''}
                onChange={handleChange}
                disabled={parentCaption}
              >
                <option value="">Выберите статью...</option>
                {captions.map((caption) => (
                  <option 
                    key={caption.id} 
                    value={caption.id}
                    selected={
                      caption.id === parentCaption || 
                      caption.id === newCaption.parent_caption
                    }
                  >
                    {caption.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Описание</Form.Label>
              <Form.Control
                type="text"
                name="description"
                value={newCaption.description}
                placeholder="Введите описание"
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Отмена
          </Button>
          <Button variant="primary" onClick={handleModalSave}>
            Сохранить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


