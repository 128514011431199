import React, { useState, useMemo } from 'react';
import ReactLoading from 'react-loading';
import Header from "../Header";
import api from '../../api';
import SideBar from '../SideBar';
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";

const initialData = [
    {
      id: 1,
      name: "Операционная деятельность",
      total: { plan: 85000000, fact: 85000000, deviation: 0 },
      months: {
        "Январь": { plan: 50000, fact: 50000, deviation: 0 },
        "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
        "Март": { plan: 20000, fact: 20000, deviation: 0 },
        "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
        "Май": { plan: 20000, fact: 20000, deviation: 0 },
        "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
        "Июль": { plan: 20000, fact: 20000, deviation: 0 },
        "Август": { plan: 20000, fact: 20000, deviation: 0 },
        "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
      },
      children: [
        {
          id: 11,
          name: "Доходы в рамках контракта",
          total: { plan: 85000000, fact: 85000000, deviation: 0 },
          months: {
            "Январь": { plan: 5000, fact: 5000, deviation: 0 },
            "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
            "Март": { plan: 20000, fact: 20000, deviation: 0 },
            "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
            "Май": { plan: 20000, fact: 20000, deviation: 0 },
            "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
            "Июль": { plan: 20000, fact: 20000, deviation: 0 },
            "Август": { plan: 20000, fact: 20000, deviation: 0 },
            "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }          
        },
          children: [
            {
              id: 111,
              name: "Доход за сайт",
              total: { plan: 75000000, fact: 85000000, deviation: 10000000 },
              months: {
                "Январь": { plan: 20000, fact: 20000, deviation: 0 },
                "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
                "Март": { plan: 20000, fact: 20000, deviation: 0 },
                "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
                "Май": { plan: 20000, fact: 20000, deviation: 0 },
                "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
                "Июль": { plan: 20000, fact: 20000, deviation: 0 },
                "Август": { plan: 20000, fact: 20000, deviation: 0 },
                "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
              }
            },
            // ... other items
          ]
        },
        // ... other items
      ]
    },
    {
      id: 2,
      name: "Финансовая деятельность",
      total: { plan: 85000000, fact: 85000000, deviation: 0 },
      months: {
        "Январь": { plan: 50000, fact: 50000, deviation: 0 },
        "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
        "Март": { plan: 20000, fact: 20000, deviation: 0 },
        "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
        "Май": { plan: 20000, fact: 20000, deviation: 0 },
        "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
        "Июль": { plan: 20000, fact: 20000, deviation: 0 },
        "Август": { plan: 20000, fact: 20000, deviation: 0 },
        "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
      },
      children: [
        {
          id: 121,
          name: "Заработная плата",
          total: { plan: 85000000, fact: 85000000, deviation: 0 },
          months: {
            "Январь": { plan: 5000, fact: 5000, deviation: 0 },
            "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
            "Март": { plan: 20000, fact: 20000, deviation: 0 },
            "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
            "Май": { plan: 20000, fact: 20000, deviation: 0 },
            "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
            "Июль": { plan: 20000, fact: 20000, deviation: 0 },
            "Август": { plan: 20000, fact: 20000, deviation: 0 },
            "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }          
        },
          children: [
            {
              id: 1121,
              name: "Налоги",
              total: { plan: 75000000, fact: 85000000, deviation: 10000000 },
              months: {
                "Январь": { plan: 20000, fact: 20000, deviation: 0 },
                "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
                "Март": { plan: 20000, fact: 20000, deviation: 0 },
                "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
                "Май": { plan: 20000, fact: 20000, deviation: 0 },
                "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
                "Июль": { plan: 20000, fact: 20000, deviation: 0 },
                "Август": { plan: 20000, fact: 20000, deviation: 0 },
                "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
              },
              children: [
                {
                  id: 4231,
                  name: "Операционная деятельность",
                  total: { plan: 75000000, fact: 85000000, deviation: 10000000 },
                  months: {
                    "Январь": { plan: 20000, fact: 20000, deviation: 0 },
                    "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
                    "Март": { plan: 20000, fact: 20000, deviation: 0 },
                    "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
                    "Май": { plan: 20000, fact: 20000, deviation: 0 },
                    "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
                    "Июль": { plan: 20000, fact: 20000, deviation: 0 },
                    "Август": { plan: 20000, fact: 20000, deviation: 0 },
                    "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
                  }
                },
              ]
            },{
              id: 11211,
              name: "Подарки на новый год",
              total: { plan: 75000000, fact: 85000000, deviation: 10000000 },
              months: {
                "Январь": { plan: 20000, fact: 20000, deviation: 0 },
                "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
                "Март": { plan: 20000, fact: 20000, deviation: 0 },
                "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
                "Май": { plan: 20000, fact: 20000, deviation: 0 },
                "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
                "Июль": { plan: 20000, fact: 20000, deviation: 0 },
                "Август": { plan: 20000, fact: 20000, deviation: 0 },
                "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
              }
            },
            // ... other items
          ]
        },{
          id: 1221,
          name: "Фитнес",
          total: { plan: 85000000, fact: 85000000, deviation: 0 },
          months: {
            "Январь": { plan: 5000, fact: 5000, deviation: 0 },
            "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
            "Март": { plan: 20000, fact: 20000, deviation: 0 },
            "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
            "Май": { plan: 20000, fact: 20000, deviation: 0 },
            "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
            "Июль": { plan: 20000, fact: 20000, deviation: 0 },
            "Август": { plan: 20000, fact: 20000, deviation: 0 },
            "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }          
        },
          children: [
            {
              id: 11521,
              name: "Налоги",
              total: { plan: 75000000, fact: 85000000, deviation: 10000000 },
              months: {
                "Январь": { plan: 20000, fact: 20000, deviation: 0 },
                "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
                "Март": { plan: 20000, fact: 20000, deviation: 0 },
                "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
                "Май": { plan: 20000, fact: 20000, deviation: 0 },
                "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
                "Июль": { plan: 20000, fact: 20000, deviation: 0 },
                "Август": { plan: 20000, fact: 20000, deviation: 0 },
                "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
              }
            },{
              id: 1111,
              name: "Подарки на новый год",
              total: { plan: 75000000, fact: 85000000, deviation: 10000000 },
              months: {
                "Январь": { plan: 20000, fact: 20000, deviation: 0 },
                "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
                "Март": { plan: 20000, fact: 20000, deviation: 0 },
                "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
                "Май": { plan: 20000, fact: 20000, deviation: 0 },
                "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
                "Июль": { plan: 20000, fact: 20000, deviation: 0 },
                "Август": { plan: 20000, fact: 20000, deviation: 0 },
                "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
              }
            },
            // ... other items
          ]
        },
        // ... other items
      ]
    },
    {
      id: 3,
      name: "Инвестиционная деятельность",
      total: { plan: 85000000, fact: 85000000, deviation: 0 },
      months: {
        "Январь": { plan: 50000, fact: 50000, deviation: 0 },
        "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
        "Март": { plan: 20000, fact: 20000, deviation: 0 },
        "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
        "Май": { plan: 20000, fact: 20000, deviation: 0 },
        "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
        "Июль": { plan: 20000, fact: 20000, deviation: 0 },
        "Август": { plan: 20000, fact: 20000, deviation: 0 },
        "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
      },
      children: [
        {
          id: 11,
          name: "Доходы в рамках контракта",
          total: { plan: 85000000, fact: 85000000, deviation: 0 },
          months: {
            "Январь": { plan: 5000, fact: 5000, deviation: 0 },
            "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
            "Март": { plan: 20000, fact: 20000, deviation: 0 },
            "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
            "Май": { plan: 20000, fact: 20000, deviation: 0 },
            "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
            "Июль": { plan: 20000, fact: 20000, deviation: 0 },
            "Август": { plan: 20000, fact: 20000, deviation: 0 },
            "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }          
        },
          children: [
            {
              id: 111,
              name: "Доход за сайт",
              total: { plan: 75000000, fact: 85000000, deviation: 10000000 },
              months: {
                "Январь": { plan: 20000, fact: 20000, deviation: 0 },
                "Февраль": { plan: 20000, fact: 20000, deviation: 0 },
                "Март": { plan: 20000, fact: 20000, deviation: 0 },
                "Апрель": { plan: 20000, fact: 20000, deviation: 0 },
                "Май": { plan: 20000, fact: 20000, deviation: 0 },
                "Июнь": { plan: 20000, fact: 20000, deviation: 0 },
                "Июль": { plan: 20000, fact: 20000, deviation: 0 },
                "Август": { plan: 20000, fact: 20000, deviation: 0 },
                "Сентябрь": { plan: 20000, fact: 20000, deviation: 0 }
              }
            },
            // ... other items
          ]
        },
        // ... other items
      ]
    },
    // ... other main categories
  ];
  const TableRow = ({ item, depth = 0, onToggle, isEditing, months }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const hasChildren = item.children && item.children.length > 0;
  
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
      if (onToggle) onToggle();
    };
  
    return (
      <>
        <tr className={depth === 0 ? 'fw-bold' : ''}>
          <td>
            <div className="d-flex align-items-center">
              <span style={{ marginLeft: `${depth * 20}px` }}></span>
              {hasChildren && (
                <button onClick={toggleExpand} className="btn btn-sm btn-link p-0 me-2">
                  <i className={`bi ${isExpanded ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                </button>
              )}
              {item.name}
              {isEditing && (<>
              <i className='bi bi-plus-circle ms-2'></i>
              <i className='bi bi-folder-plus ms-2'></i>
              <i className='bi bi-trash3 ms-2'></i></>)}
            </div>
          </td>
          <td className="text-end">{item.total.plan.toLocaleString()}</td>
          <td className="text-end">{item.total.fact.toLocaleString()}</td>
          <td className="text-end">{item.total.deviation.toLocaleString()}</td>
          {months.map(month => (
            <React.Fragment key={month}>
              <td className="text-end">{item.months[month]?.plan.toLocaleString() || '-'}</td>
              <td className="text-end">{item.months[month]?.fact.toLocaleString() || '-'}</td>
              <td className="text-end">{item.months[month]?.deviation.toLocaleString() || '-'}</td>
            </React.Fragment>
          ))}
        </tr>
        {isExpanded && item.children && item.children.map(child => (
          <TableRow key={child.id} item={child} depth={depth + 1} onToggle={onToggle} months={months} isEditing={isEditing}/>
        ))}
      </>
    );
  };
  

const BudgetPlanPage = () => {
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [data] = useState(initialData);
    const months = useMemo(() => {
    const allMonths = new Set();
    const extractMonths = (item) => {
        Object.keys(item.months).forEach(month => allMonths.add(month));
        if (item.children) {
        item.children.forEach(extractMonths);
        }
    };
    data.forEach(extractMonths);
    return Array.from(allMonths);
    }, [data]);

    const handleEditClick = () => {
      setIsEditing(!isEditing);
    };

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="budgets-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3"><h3>Бюджетирование</h3></div>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="filter row flex-fill justify-content-center border rounded p-3">
                                        <div className="col">
                                            <label className="form-label">Проект</label>
                                            <APIMultipleSearchableSelect
                                                endpoint="/api/v1/organization/projects/search/"
                                                placeholder="Выберите проект"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Финмодель</label>    
                                            <APIMultipleSearchableSelect
                                                endpoint="/api/v1/organization/projects/search/"
                                                placeholder="Выберите финмодель"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Организации</label>    
                                            <APIMultipleSearchableSelect
                                                endpoint="/api/v1/organization/search/"
                                                placeholder="Выберите организации"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Период активности проекта</label>    
                                            <div className="d-flex justify-content-center align-items-center align-self-center">
                                                <input type="date" className='form-control form-control-sm' name="date_from" />
                                                —
                                                <input type="date" className='form-control form-control-sm' name="date_to" />
                                            </div>
                                        </div>
                                        <div className="col d-flex ms-auto justify-content-evenly align-items-center">
                                            <button className="btn btn-secondary">Сбросить</button>
                                            <button className="btn btn-primary">Поиск</button>
                                        </div>
                                    </div>                               
                                    <div className="ms-4">
                                        <button className='btn btn-primary' data-bs-toggle="dropdown">Действие</button>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" onClick={handleEditClick}>Редактировать бюджет</a>
                                                <a className="dropdown-item">Открыть редактирование</a>
                                                <a className="dropdown-item">Закрыть редактирование</a>
                                                <a className="dropdown-item">Поменять ответсвенного</a>
                                                <a className="dropdown-item">Архивировать бюджет</a>
                                                <a className="dropdown-item">Разархивировать бюджет</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {isEditing && <div>
                                    <button className='btn btn-success mb-2' onClick={handleEditClick}>
                                      {isEditing ? 'Закончить редактирование' : 'Редактировать бюджет'}
                                    </button>
                                  </div>}
                                <div className="table-responsive table-container" style={{ height: '75vh', overflowY: 'scroll' }}>
                                <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th rowSpan="2">Статьи</th>
                                        <th colSpan="3">План итого</th>
                                        {months.map(month => (
                                        <th key={month} colSpan="3">{month}</th>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th>План</th>
                                        <th>Факт</th>
                                        <th>Отклонение</th>
                                        {months.map(month => (
                                        <React.Fragment key={`header-${month}`}>
                                            <th>План</th>
                                            <th>Факт</th>
                                            <th>Отклонение</th>
                                        </React.Fragment>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map(item => (
                                        <TableRow key={item.id} item={item} months={months} isEditing={isEditing} />
                                    ))}
                                    </tbody>
                                </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default BudgetPlanPage