import SideBar from "../SideBar";
import { useEffect, useState } from "react";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import Header from "../Header";

const CreateBankAccount = () => {
    const [formData, setFormData] = useState({
        origin: '',
        code: '',
        account_type: '',
        account_number: '',
        company: '',
        bank: '',
        currency: '',
        owner_organization: '',
        owner_counterparty: ''
      });
    
    const [counterparties, setCounterparties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [banks, setBankaccounts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const navigate = useNavigate()


    const fetchCounterparty = async () => {
        try {
            const res = await api.get('api/v1/bills/counterparty/');
            setCounterparties(res.data);
        } catch (error) {
            console.error("Error fetching counterpaties", error)
        }
    }

    const fetchBankAccounts = async () => {
        try {
            const response = await api.get('/api/v1/bank/');
            setBankaccounts(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error)
        }
    }

    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }

    const fetchCurrency = async () => {
        try {
            const response = await api.get('/api/v1/bank/currencies/');
            setCurrencies(response.data);
        } catch (error) {
            console.error('Error fetching currencies', error)
        }
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCounterparty(),
                fetchBankAccounts(),
                fetchCompanies(),
                fetchCurrency(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await api.post('api/v1/bank/createbankaccount/', formData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log('Response:', response.data);
          navigate('/bankacc')
        } catch (error) {
            alert('Заполните все поля')
            console.error('Error:', error);
        }
      };
    
      if (loading) {
        return <div>Loading...</div>; // Display a loading indicator while fetching data
    }

    return (
        <>
        <div className="container-fluid">
        <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-4">
                    <div className="create-header">
                        <h1>Добавление нового банковского счета</h1>
                    </div>
                    <div className="create-bankaccount-content">
                        <form className="bankaccount-form" onSubmit={handleSubmit}>
                            <div className='form_input'>
                                <div className="row g-3">
                                    <div className='col-md-2'>
                                    <label className='form-label'>Наименование</label>
                                    <input className="form-control" type="text" name="name" placeholder='Наименование' onChange={handleChange}/>
                                    </div>
                                    <div className='col-md-2'>
                                    <label className='form-label'>Организация</label>
                                        <select
                                            className="form-select"
                                            name="company"
                                            value={formData.company || ''}
                                            onChange={handleChange}>
                                            <option value="">
                                                {formData.company ? companies.find(company => company.id === formData.company)?.name : 'Выберите организацию...'}
                                            </option>
                                            {companies.map((company) => (
                                                <option key={company.id} value={company.id}>
                                                    {company.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-2'>
                                    <label className='form-label'>Код</label>
                                    <input className="form-control" type="text" name="code" onChange={handleChange} placeholder='Введите код'/>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-3">
                                    <label className='form-label'>Вид счета</label>
                                    <input className="form-control" type="text" name="account_type" onChange={handleChange} placeholder='Введите вид счета'/>
                                    </div>
                                    <div className='col-md-3'>
                                    <label className='form-label'>Банк</label>
                                        <select
                                            className="form-select"
                                            name="bank"
                                            value={formData.bank || ''}
                                            onChange={handleChange}>
                                            <option value="">
                                                {formData.bank ? banks.find(bankaccount => bankaccount.id === formData.bank)?.name : 'Выберите банк...'}
                                            </option>
                                            {banks.map((bank) => (
                                                <option key={bank.id} value={bank.id}>
                                                    {bank.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className='col-md-3'>
                                    <label className='form-label'>Валюта</label>
                                        <select
                                            className="form-select"
                                            value={formData.currency || ''}
                                            name="currency"
                                            onChange={handleChange}>
                                            <option value="">
                                                {formData.currency ? currencies.find(currency => currency.id === formData.currency)?.name : 'Выберите валюту...'}
                                            </option>
                                            {currencies.map((currency) => (
                                                <option key={currency.id} value={currency.id}>
                                                    {currency.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-2'>
                                    <label className='form-label'>Номер счета</label>
                                    <input className="form-control" type="text" name="account_number" onChange={handleChange} placeholder='Введите номер счета'/>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className='col-md-3'>
                                    <label className='form-label'>Владелец(Организация)</label>
                                        <select
                                            className="form-select"
                                            name="owner_organization"
                                            value={formData.owner_organization || ''}
                                            onChange={handleChange}>
                                            <option value="">
                                                {formData.owner_organization ? companies.find(company => company.id === formData.owner_organization)?.name : 'Выберите организацию...'}
                                            </option>
                                            {companies.map((owner_organization) => (
                                                <option key={owner_organization.id} value={owner_organization.id}>
                                                    {owner_organization.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-md-3'>
                                    <label className='form-label'>Владелец(Контрагент)</label>
                                    <select
                                            className="form-select"
                                            name="owner_counterparty"
                                            value={formData.counterparty || ''}
                                            onChange={handleChange}>
                                            <option value="">
                                                {formData.owner_counterparty ? counterparties.find(counterparty => counterparty.id === formData.owner_counterparty)?.name : 'Выберите контрагента...'}
                                            </option>
                                            {counterparties.map((owner_counterparty) => (
                                                <option key={owner_counterparty.id} value={owner_counterparty.id}>
                                                    {owner_counterparty.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Сохранить</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default CreateBankAccount;