import SideBar from "../SideBar";
import { useEffect, useState } from "react";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../functions/UprData";
import Header from "../Header";

const CaptionCreate = () => {
    const [caption, setCaption] = useState({
        name: '',
        description: '',
        parent_caption: '',
        type_of_caption: ''
      });
    const navigate = useNavigate();
    const [captions, setCaptions] = useState([]);
    const [caption_types, setCaptionTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCaption({
          ...caption,
          [name]: value
        });
      };

    const fetchCaptions = useFetchData('api/v1/bills/caption/', setCaptions);
    const fetchCaptionTypes = useFetchData('api/v1/bills/caption-types/', setCaptionTypes);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCaptions(),
                fetchCaptionTypes(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/v1/bills/caption-create/', caption);
            const newCaption = response.data;
            console.log('new Caption:', newCaption);
            navigate('/captions')
            
        } catch (error) {
            alert("Заполните все поля");
            console.error('An error occurred while creating new caption', error);
        }
    };

    if (loading) {
        return (
            <>Loading...</>
        );
    }

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar />
                <div className='col py-3'>
                    <h2>Создать статью</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className='form-label'>Наименование</label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={caption.name || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">Тип статьи</label>
                            <select
                                className="form-select"
                                name="type_of_caption"
                                value={caption.type_of_caption || ''}
                                onChange={handleChange}>
                                <option value="">
                                    {caption.type_of_caption ? caption_types.find(caption_type => caption_type.id === caption.caption_type)?.name : 'Выберите тип статьи...'}
                                </option>
                                {caption_types.map((caption_type) => (
                                    <option key={caption_type.id} value={caption_type.id}>
                                        {caption_type.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label className="form-label">Родительская статья</label>
                            <select
                                className="form-select"
                                name="parent_caption"
                                value={caption.parent_caption || ''}
                                onChange={handleChange}>
                                <option value="">
                                    {caption.parent_caption ? captions.find(parent_caption => parent_caption.id === caption.parent_caption)?.name : 'Выберите родительскую статью...'}
                                </option>
                                {captions.map((parent_caption) => (
                                    <option key={parent_caption.id} value={parent_caption.id}>
                                        {parent_caption.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className='form-label'>Описание</label>
                            <input
                                type="text"
                                className="form-control"
                                name="description"
                                value={caption.description || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CaptionCreate;