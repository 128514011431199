import { forwardRef } from "react";
import { useAuth } from "../AuthContext";

const ProtectedContent = forwardRef((props, ref) => {
  const { children, groups, perms, alt } = props;
  const { user, permissions, user_groups } = useAuth();


  let showContent = false;

  // Block rendering content if the user is not authenticated
  if (!user) {
    return <p>User is not authenticated!</p>;
  }

  // If the user is a superuser, grant access to everything
  if (user?.is_superuser) {
    showContent = true;
  } else {
    showContent = user_groups.some((group) => groups?.includes(group));

    if (!showContent) {
      showContent = permissions.some((perm) => perms?.includes(perm));
    }
  }

  return showContent ? <>{children}</> : <>{alt || null}</>;
});

export default ProtectedContent;
