import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import api from "../../api";
import SideBar from "../SideBar";
import Header from "../Header";

const CounterPartyEdit = () => {
    const { id } = useParams();
    const [companies, setCompanies] = useState([]);
    const [counterparties, setCounterparties] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate()

    const fetchCounterparty = async () => {
        try {
            const response = await api.get(`api/v1/bills/counterparty/${id}/`);
            setCounterparties(response.data);
        } catch (error) {
            console.error('Error fetching counterparty', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchCompanies(),
                fetchCounterparty(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]); // Ensure it runs only once when the component mounts

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCounterparties((prevCounterparty) => ({
            ...prevCounterparty,
            [name]: value,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const preparedCounterparty = {
                ...counterparties,
            };

            const response = await api.patch(`/api/v1/bills/counterparty/${id}/update`, preparedCounterparty);


            const updatedCounterparty = await response.data;
            console.log('Updated Counterparty:', updatedCounterparty);
            navigate('/counterparty')
        } catch (error) {
            console.error('Error updating Counterparty:', error);
        }
    };

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <h2>Редактировать контрагентов</h2>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Наименование контрагента</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={counterparties.name || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Код</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="account_number"
                                    value={counterparties.code || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Организация</label>
                                <select
                                        className="form-select"
                                        name="company"
                                        value={counterparties.company || ''}
                                        onChange={handleChange}>
                                        <option value="">
                                            {counterparties.company ? companies.find(company => company.id === counterparties.company)?.name : 'Выберите организацию...'}
                                        </option>
                                        {companies.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">БИН/ИИН</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="code"
                                    value={counterparties.bin_iin || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            
                           
                            <button type="submit" className="btn btn-primary">
                                Сохранить
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CounterPartyEdit;
