// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.distributed {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: auto;
}

.filters-panel {
    width: 300px;
    background-color: #f8f9fa;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
    display: none;
    transition: transform 0.3s ease-in-out;
}

.filters-panel.visible {
    display: block;
}

.show-filters-btn {
    background-color:white;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 7px 0 0;
}

.show-filters-btn:hover {
    background-color: #d9d9da;
}

.btn-closeleft {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    background: transparent;
    border: none;
}
.filter-icon {
    width: 30px;  
    height: 30px;
    object-fit: contain;
  }

.filter-icon-close {
    width: 30px;  
    height: 30px;
    object-fit: contain;
  }
.filters-panel .d-flex input[type="date"] {
    width: calc(50% - 36px);
    box-sizing: border-box; 
  }
.form-group{
    margin-top: 17px;
}
.apply{
    margin-top: 17px;
}

`, "",{"version":3,"sources":["webpack://./src/styles/paymentStyle.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,uCAAuC;IACvC,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;;AAEF;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;AACF;IACI,uBAAuB;IACvB,sBAAsB;EACxB;AACF;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".distributed {\r\n    width: 16px;\r\n    height: 16px;\r\n    border-radius: 50%;\r\n    margin: auto;\r\n}\r\n\r\n.filters-panel {\r\n    width: 300px;\r\n    background-color: #f8f9fa;\r\n    padding: 20px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n    position: relative;\r\n    z-index: 10;\r\n    display: none;\r\n    transition: transform 0.3s ease-in-out;\r\n}\r\n\r\n.filters-panel.visible {\r\n    display: block;\r\n}\r\n\r\n.show-filters-btn {\r\n    background-color:white;\r\n    color: white;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    margin: 0 7px 0 0;\r\n}\r\n\r\n.show-filters-btn:hover {\r\n    background-color: #d9d9da;\r\n}\r\n\r\n.btn-closeleft {\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n    font-size: 1.5rem;\r\n    background: transparent;\r\n    border: none;\r\n}\r\n.filter-icon {\r\n    width: 30px;  \r\n    height: 30px;\r\n    object-fit: contain;\r\n  }\r\n\r\n.filter-icon-close {\r\n    width: 30px;  \r\n    height: 30px;\r\n    object-fit: contain;\r\n  }\r\n.filters-panel .d-flex input[type=\"date\"] {\r\n    width: calc(50% - 36px);\r\n    box-sizing: border-box; \r\n  }\r\n.form-group{\r\n    margin-top: 17px;\r\n}\r\n.apply{\r\n    margin-top: 17px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
