import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import api from "../../api";
import SideBar from "../SideBar";
import useFetchData from "../../functions/UprData";
import { CaptionForm } from "../captions/CaptionForm";
import Header from "../Header";
import APISearchableSelect from './SearchableSelect';
import "../../styles/createPayment.css";
import ReactLoading from 'react-loading';
import { Alert } from 'react-bootstrap';

const PaymentEdit = () => {

    const { id } = useParams();
    const [payment, setPayment] = useState({});
    const [captions, setCaptions] = useState([]);

    const [loading, setLoading] = useState(true);

    const [showDecipher, setShowDecipher] = useState(false);

    const [caption_types, setCaptionTypes] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    const navigate = useNavigate()
    const [decipherFields, setDecipherFields] = useState({
        sum: true,
        nds_sum: false,
        nds_rate: false,
        counterparty: false,
        caption: false,
        project: false,
        employee: false
      });

    const updateDecipherFields = (decipher) => {
        const newFields = { ...decipherFields };

        Object.keys(newFields).forEach((key) => {
            newFields[key] = decipher[0][key] !== null && decipher[0][key] !== 0;
        });

        setDecipherFields(newFields);
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'counterparty') {
            handleChange({target:  { name: "counterparty", value: '' } });
            handleChange({target:  { name: "counterparty_account", value: '' } });
        } else if (name === 'caption') {
            handleChange({target:  { name: "caption", value: '' } });
            handleChange({target:  { name: "project", value: '' } });
        }
        setDecipherFields({
            ...decipherFields,
            [name]: checked
        });
    };

    const fetchPayment = async () => {
        try {
            const response = await api.get(`api/v1/bills/payments/${id}/`);
            setPayment(response.data);
            setShowDecipher((response.data.decipher && response.data.decipher.length > 0));
            if (response.data.decipher) {
                updateDecipherFields(response.data.decipher);
            }
        } catch (error) {
            console.error('Error fetching payment', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddDecipher = () => {
        // const newDecipher = [...payment.decipher, { ...payment.decipher[payment.decipher.length - 1] }];
        const draftDecipher = {
            "employee": null,
            "counterparty": null,
            "caption": null,
            "project": null,
            "sum": 0,
            "nds_sum": 0,
            "nds_rate": 0,
            "type_of_debt": null,
            "accountable": null,
            "payment": id
        }
        const newDecipher = [...payment.decipher, { ...draftDecipher }];
        setPayment(prevPayment => ({
            ...prevPayment,
            decipher: newDecipher,
        }));
    };

    const fetchCaption = useFetchData('/api/v1/bills/caption/', setCaptions);

    const fetchCaptionTypes = useFetchData('/api/v1/bills/caption-types/', setCaptionTypes);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchPayment(),
                fetchCaption(),
                fetchCaptionTypes(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayment((prevPayment) => ({
            ...prevPayment,
            [name]: value,
        }));
    };


    const formatCurrency = (value) => {
        return new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(value);
    };

    const parseCurrency = (value) => {
        return parseFloat(value.replace(/\s+/g, '').replace(',', '.')) || 0;
    };
    
    const handleSelectChange = (index, field) => {
        const currentTotal = payment.decipher.reduce((acc, param, i) => {
            return i === index ? acc : acc + (param.sum || 0);
        }, 0);

        const newSum = parseFloat(field.value) || 0;
        const newTotal = currentTotal + newSum;

        if (field.name === "sum" && newTotal <= payment.total_sum) {
            setShowAlert(false);
        }
    

        const updatedDecipher = payment.decipher.map((param, i) => {
            if (i === index) {
                const updatedParam = { ...param, [field.name]: field.value };

                if (field.name === "sum" || field.name === "nds_rate") {
                    updatedParam.nds_sum = (updatedParam.sum || 0) * (updatedParam.nds_rate || 0) / 100;
                }
    
                return updatedParam;
            }
            return param;
        }); 
        setPayment((prevPayment) => ({
            ...prevPayment,
            decipher: updatedDecipher,
        }));
        console.log(updatedDecipher)
    };
 
    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const getIdOrValue = (obj) => obj?.id || obj || '';
        const decipherSumTotal = payment.decipher.reduce((sum, param) => {
            const parsedSum = parseFloat(param.sum) || 0; 
            return sum + parsedSum;
        }, 0);

        if (decipherSumTotal > payment.total_sum) {
            setShowAlert(true);
            return;
        }
        try {
            const preparedPayment = {
                ...payment,
                company: getIdOrValue(payment.company),
                company_account: getIdOrValue(payment.company_account),
                document_type: getIdOrValue(payment.document_type),
                type_of_operation: getIdOrValue(payment.type_of_operation),
                project: getIdOrValue(payment.project),
                caption: getIdOrValue(payment.caption),
                counterparty: getIdOrValue(payment.counterparty),
                counterparty_account: getIdOrValue(payment.counterparty_account),
                decipher: payment.decipher.map(param => ({
                    ...param,
                    caption: getIdOrValue(param.caption),
                    project:getIdOrValue(param.project),
                    counterparty: getIdOrValue(param.counterparty),
                    employee: getIdOrValue(param.employee)
                }))
            };

            const response = await api.patch(`/api/v1/bills/payments/${id}/update/`, preparedPayment);

            const updatedPayment = response.data;
            console.log('Updated Payment:', updatedPayment);
            navigate('/payment-registry')
        } catch (error) {
            console.error('Error updating payment:', error);
        }
    };

    const handleRemoveDecipher = (index) => {
        setPayment(prevPayment => ({
            ...prevPayment,
            decipher: prevPayment.decipher.filter((_, i) => i !== index),
        }));
    };

    const handleShowDecipher = (checkbox) => {
        if (checkbox === true) {
            handleAddDecipher();
            setShowDecipher(true);
        } else {
            setPayment({
                ...payment,
                decipher: []
            });
            setDecipherFields({
                sum: true,
                nds_sum: false,
                nds_rate: false,
                counterparty: false,
                caption: false,
                project: false,
                employee: false
              });
            setShowDecipher(false);
        }
    }

    return (
        <div className="container-fluid" translate="no">
            <Header />
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <h2>Редактировать Платеж</h2>
                    {loading ? (
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Документ</label>
                                <APISearchableSelect
                                        endpoint="/api/v1/bills/document-types/search/"
                                        value={payment?.document_type}
                                        onChange={(value) => handleChange({ target: { name: "document_type", value } })}
                                        placeholder="Выберите тип документа"
                                    />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Тип операции</label>
                                <APISearchableSelect
                                        endpoint="/api/v1/bills/operation-types/search/"
                                        value={payment?.type_of_operation}
                                        onChange={(value) => handleChange({ target: { name: "type_of_operation", value } })}
                                        placeholder="Выберите вид операции"
                                    /> 
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Дата платежа</label>
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    value={formatDate(payment.date)}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Банковский счет</label>
                                <APISearchableSelect
                                        endpoint="/api/v1/bank/bankaccounts/search/"
                                        value={payment?.company_account}
                                        onChange={(value) => handleChange({ target: { name: "company_account", value } })}
                                        placeholder="Выберите счет организации"
                                    />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Сумма</label>
                                <input 
                                    className="form-control form-control-sm" 
                                    type="text" 
                                    name="total_sum" 
                                    placeholder='Введите сумму' 
                                    value={formatCurrency(payment.total_sum)} 
                                    onChange={(e) => handleChange({ target: { name: "total_sum", value: parseCurrency(e.target.value) } })}
                                />
                            </div>
                            <div className={payment.decipher.length > 0 ? "d-none" : "mb-3"}>
                                <label className="form-label">Проект:</label>
                                    <APISearchableSelect
                                        endpoint="/api/v1/organization/projects/search/"
                                        value={payment?.project} 
                                        onChange={(value) => handleChange({target:  { name: "project", value } })}
                                        placeholder="Выберите проект"
                                    />
                            </div>
                            <div className={payment.decipher.length > 0 ? "d-none" : "mb-3"}>
                                <label className="form-label">Статья:</label>
                                    <APISearchableSelect
                                        endpoint="/api/v1/bills/caption/search/"
                                        value={payment?.caption} 
                                        onChange={(value) => handleChange({target:  { name: "caption", value } })}
                                        placeholder="Выберите статью"
                                    />
                            </div>
                            <div className='decipher-form'>
                            <div>
                                <label className='form-label'>
                                    <input
                                    type="checkbox"
                                    className="from-control"
                                    checked={showDecipher}
                                    onChange={(e) => handleShowDecipher(e.target.checked)}
                                    />
                                    Разнести
                                </label>
                            </div>
                            {showDecipher && (
                                <div className="deciphercontent">
                                    <h6>Выберите параметр распределения</h6>
                                    <div className="params mt-2">
                                        <label className='me-2'>
                                            <input
                                            type="checkbox"
                                            name="employee"
                                            checked={decipherFields.employee}
                                            onChange={handleCheckboxChange}
                                            />
                                            Физ. лицо
                                        </label>
                                        <label className='me-2'>
                                            <input
                                            type="checkbox"
                                            name="counterparty"
                                            checked={decipherFields.counterparty}
                                            onChange={handleCheckboxChange}
                                            />
                                            Контрагент
                                        </label>
                                        <label>
                                            <input
                                            type="checkbox"
                                            name="nds_rate"
                                            checked={decipherFields.nds_rate}
                                            onChange={handleCheckboxChange}
                                            />
                                            Ставка НДС
                                        </label>
                                        {/* <label className='me-2'>
                                            <input
                                            type="checkbox"
                                            name="caption"
                                            checked={decipherFields.caption}
                                            onChange={handleCheckboxChange}
                                            />
                                            Статья и Проект
                                        </label> */}
                                        {/* <label className='me-2'>
                                            <input
                                            type="checkbox"
                                            name="project"
                                            checked={decipherFields.project}
                                            onChange={handleCheckboxChange}
                                            />
                                            Проект
                                        </label> */}
                                    </div>
                                    <div className='deciphers'>
                                    {payment.decipher.map((param, index) => (
                                        <div key={index} className="decipher row py-3">
                                            <div className="col-md-11 row">
                                            <div className="col-md-4">
                                                <label className="form-label">Сумма</label>
                                                <input 
                                                    className="form-control form-control-sm" 
                                                    type="text" 
                                                    name="sum" 
                                                    placeholder='Введите сумму' 
                                                    value={formatCurrency(param.sum)} 
                                                    onChange={(e) => handleSelectChange(index, {name: "sum", value: parseCurrency(e.target.value)})}
                                                />
                                            </div>
                                            {decipherFields.employee && (
                                                <div className="col-md-4">
                                                <label className="form-label">Физ. лицо:</label>
                                                    <APISearchableSelect
                                                        endpoint="/api/v1/bills/individual/search/"
                                                        value={param?.employee} 
                                                        size=''
                                                        onChange={(value) => handleSelectChange(index, { name: "employee", value } )}
                                                        placeholder="Выберите сотрудника"
                                                    />
                                                </div>
                                            )}
                                            {decipherFields.counterparty && (
                                                <div className="col-md-4">
                                                <label className="form-label">Контрагент</label>
                                                <APISearchableSelect
                                                    endpoint="/api/v1/bills/counterparty/"
                                                    value={param?.counterparty} 
                                                    size=''
                                                    onChange={(value) => handleSelectChange(index, { name: "counterparty", value } )}
                                                    placeholder="Выберите контрагента"
                                                />
                                            </div>)}
                                            {decipherFields.nds_rate && (
                                                <div className="col-md-4">
                                                <label className="form-label">Ставка НДС</label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control"
                                                    name="nds_rate"
                                                    min="0"
                                                    step="any"
                                                    value={param.nds_rate || ''}
                                                    onChange={(e) =>
                                                        handleSelectChange(index, e.target)
                                                    }
                                                />
                                            </div>)}
                                            {decipherFields.nds_rate && (
                                                <div className="col-md-4">
                                                <label className="form-label">Сумма НДС</label>
                                                <input 
                                                    className="form-control form-control-sm" 
                                                    type="text" 
                                                    name="commission_sum" 
                                                    placeholder='Введите сумму' 
                                                    value={formatCurrency(param.nds_sum)} 
                                                    onChange={(e) => handleSelectChange(index, e.target)}
                                                />                                                                                  
                                                </div>)}
        

                                            <CaptionForm 
                                                key={index} 
                                                captions={captions} 
                                                setCaptions={setCaptions}
                                                param={param}
                                                index={index}
                                                handleSelectChange={handleSelectChange}
                                                caption_types={caption_types}
                                                />
                                            <div className="col-md-4">
                                                <label className="form-label">Проект</label>
                                                <APISearchableSelect
                                                    endpoint="/api/v1/organization/projects/search/"
                                                    value={param?.project} 
                                                    size=''
                                                    onChange={(value) => handleSelectChange(index, { name: "project", value } )}
                                                    placeholder="Выберите проект"
                                                />
                                            </div>
                                            </div>
                                            <div className="col-md-1 d-flex align-items-center">
                                                    <i className="fs-5 bi bi-trash3" style={{cursor: 'pointer'}} onClick={() => handleRemoveDecipher(index)}></i>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <Alert show={showAlert} variant='danger' onClose={() => setShowAlert(false)} dismissible>
                                            <p>Общая сумма элементов превышает общую сумму платежа. Пожалуйста, скорректируйте суммы.</p>
                                        </Alert>
                                    </div>
                                    <div className="mb-3 d-flex align-items-center">
                                        <button type="button" className="btn btn-primary" onClick={handleAddDecipher}>
                                            Добавить платеж
                                        </button>
                                    </div>
                                </div>
                            )}
                            </div>
                            <div className={decipherFields.counterparty ? "d-none" : "mb-3"}>
                                <label className="form-label">Контрагент</label>
                                    <APISearchableSelect
                                        endpoint="/api/v1/bills/counterparty/search/"
                                        value={payment?.counterparty}
                                        onChange={(value) => handleChange({ target: { name: "counterparty", value } })}
                                        placeholder="Выберите контрагента"
                                    />
                            </div>
                            <div className={decipherFields.counterparty ? "d-none" : "mb-3"}>
                                <label className="form-label">Банковский счет контрагента</label>
                                    <APISearchableSelect
                                        endpoint="/api/v1/bank/counterparty-accounts/search/"
                                        value={payment?.counterparty_account}
                                        onChange={(value) => handleChange({ target: { name: "counterparty_account", value } })}
                                        placeholder="Выберите счет контрагента"
                                    />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Назначение платежа</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="purpose_of_payment"
                                    value={payment.purpose_of_payment || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Комментарий</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="comment"
                                    value={payment.comment || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Сохранить
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PaymentEdit;
