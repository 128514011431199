import React, { useState, useMemo, useEffect } from 'react';
import ReactLoading from 'react-loading';
import Header from "../Header";
import api from '../../api';
import SideBar from '../SideBar';
import APIMultipleSearchableSelect from "../payments/SearchableTableMultiSelect";
import SlidingPanel from './SlideOutPanel';
import APISearchableSelect from '../payments/SearchableSelect';
import { Modal, Form, Button } from 'react-bootstrap';
import { CaptionForm } from "../captions/CaptionForm";
import useFetchData from "../../functions/UprData";

const TableRow = ({ item, depth = 0, onToggle, isEditing, months, openCaption, typeName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [captions, setCaptions] = useState([]);
    const [caption_types, setCaptionTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    
    const hasChildren = item.children_caption && item.children_caption.length > 0;
    const fetchCaption = useFetchData('/api/v1/bills/caption/', setCaptions);
    const fetchCaptionTypes = useFetchData('/api/v1/bills/caption-types/', setCaptionTypes);
    
    const hideModal = () => {
      setIsOpen(false);
      setSelectedItem(null);
    };
    
    const showModal = () => {
      setIsOpen(true);
      setSelectedItem(item);
    };
  
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([
          fetchCaption(),
          fetchCaptionTypes(),
        ]);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const handleSelectChange = () => {
      // Handle select change logic here
    };  
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
      if (onToggle) onToggle();
    };
  
    return (
      <>
        {depth === 0 && typeName && (
                <tr className="bg-light">
                    <td colSpan="100%" className="fw-bold">
                        {typeName}
                    </td>
                </tr>
            )}
        <tr className={depth === 0 ? 'fw-bold' : ''}>
          <td>
            <div className="d-flex align-items-center">
              <span style={{ marginLeft: `${depth * 20}px` }}></span>
              {hasChildren && (
                <button onClick={toggleExpand} className="btn btn-sm btn-link p-0 me-2">
                  <i className={`bi ${isExpanded ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                </button>
              )}
              <p onClick={() => openCaption(item.caption_id)}>{item.caption_name}</p>
              {isEditing && (
                <>
                  <button onClick={showModal} className="btn btn-sm btn-link p-0 me-2">
                    <i className='bi bi-plus-circle ms-2'></i>
                  </button>
                  <i className='bi bi-pencil ms-2'></i>
                  <i className='bi bi-trash3 ms-2'></i>
                </>
              )}
            </div>
          </td>
          <td className="text-end">{item.total.plan.toLocaleString()}</td>
          <td className="text-end">{item.total.fact.toLocaleString()}</td>
          <td className="text-end">{item.total.deviation.toLocaleString()}</td>
          {months.map(month => (
            <React.Fragment key={month}>
              <td className="text-end">{item.months[month]?.plan.toLocaleString() || '-'}</td>
              <td className="text-end">{item.months[month]?.fact.toLocaleString() || '-'}</td>
              <td className="text-end">{item.months[month]?.deviation.toLocaleString() || '-'}</td>
            </React.Fragment>
          ))}
        </tr>
        {isExpanded && item.children_caption && item.children_caption.map(child => (
          <TableRow 
            key={child.caption_id} 
            item={child} 
            depth={depth + 1} 
            onToggle={onToggle} 
            months={months} 
            isEditing={isEditing}
            openCaption={openCaption}
          />
        ))}
        <Modal 
          show={isOpen} 
          onHide={hideModal} 
          dialogClassName="modal-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Добавление статьи {selectedItem && `в "${selectedItem.caption_name}"`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4">
            <CaptionForm 
              captions={captions} 
              setCaptions={setCaptions}
              handleSelectChange={handleSelectChange}
              caption_types={caption_types}
              parentCaption={selectedItem?.caption_id}
              className="w-100"
            />
          </Modal.Body>
          <Modal.Footer className='justify-content-between px-4'>
            <Button variant="secondary" onClick={hideModal}>
              Отмена
            </Button>
            <Button variant="primary">
              Сохранить
            </Button>
          </Modal.Footer>
        </Modal>
        {loading && 
          <div className="position-absolute top-50 start-50 translate-middle">
              <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
          </div>}
      </>
    );
  };
  

const BudgetPlanPage = () => {
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [projects, setProjects] = useState([]); // Selected project
    const [dateFrom, setDateFrom] = useState(""); // Start date
    const [dateTo, setDateTo] = useState(""); // End date
    const [data, setData] = useState([]); // Table data
    const [isOpen, setIsOpen] = useState(false);
    const [captionPlan, setCaptionPlan] = useState([]);
    const months = useMemo(() => {
      const monthOrder = [
        "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
      ];
    
      const allMonths = new Set();
    
      const extractMonths = (item) => {
        Object.keys(item.months || {}).forEach((month) => allMonths.add(month));
        if (item.children_caption && item.children_caption.length > 0) {
          item.children_caption.forEach(extractMonths);
        }
      };
    
      data.forEach((group) => {
        group.captions.forEach(extractMonths);
      });
    
      return Array.from(allMonths).sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));
    }, [data]);
  

    const handleEditClick = () => {
      setIsEditing(!isEditing);
    };

    const closePanel = () => {
      setIsOpen(false);
    }

    const openPanel = async (caption) => {
      try {
        setLoading(true);
        const response = await api.post(`/api/v1/budgets/budget-plan/`, {
          projects: projects,
          period_start: dateFrom,
          period_end: dateTo,
          caption: caption,
        });
        if (response.status === 200) {
          const updatedData = response.data.map((item) => ({
            ...item,
            monthlyplan: item.monthlyplan.map((month) => ({
              ...month,
              isEditing: false, // Add isEditing field here
            })),
          }));
          setCaptionPlan(updatedData); // Set transformed data to state
          setIsOpen(true);
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false);
      }
    }


    const handleSearch = async () => {
      if (projects.length === 0 || !dateFrom || !dateTo) {
        alert("Please fill in all the fields before searching.");
        return;
      }
  
      setLoading(true);
  
      try {
        const response = await api.post("/api/v1/budgets/budget_report/", {
          projects,
          period_start: dateFrom,
          period_end: dateTo,
        });
  
        setData(response.data); // Update table data with API response
      } catch (error) {
        console.error("Error fetching budget data:", error);
        alert("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    const handleAddRow = () => {
      const newRow = {
        id: Date.now(), // Unique ID for the new row
        caption: captionPlan[0]?.caption,
        budget: captionPlan[0]?.budget,
        project: captionPlan[0]?.project,
        period_start: captionPlan[0]?.period_start,
        period_end: captionPlan[0]?.period_end,
        company: captionPlan[0]?.company,
        counterparty: null,
        is_salary: captionPlan[0]?.is_salary,
        total: 0,
        monthlyplan: captionPlan[0]?.monthlyplan.map((month) => ({
          ...month,
          plan_sum: 0,
          fact_sum: 0,
        })) || [],
        isNew: true,
      };
      setCaptionPlan((prev) => [...prev, newRow]);
    };

    const handleChange = (rowId, { name, value }) => {
      setCaptionPlan((prev) =>
        prev.map((item) =>
          item.id === rowId ? { ...item, [name]: value } : item
        )
      );
    };

    const handleSaveAllRows = async () => {
      const newRows = captionPlan.filter((item) => item.isNew);
    
      // Validate required fields
      const incompleteRows = newRows.filter((row) => !row.counterparty);
      if (incompleteRows.length > 0) {
        alert("Заполните поле контрагента");
        return;
      }
    const getIdOrValue = (obj) => obj?.id || obj || '';
    // Prepare data for the backend
    const preparedRows = newRows.map((row) => ({
      caption: getIdOrValue(row.caption), // Send only the ID
      budget: getIdOrValue(row.budget),
      project: getIdOrValue(row.project),
      company: getIdOrValue(row.company),
      counterparty: getIdOrValue(row.counterparty),
      total: row.total,
      is_salary: row.is_salary,
      period_start: row.period_start,
      period_end: row.period_end,
      monthlyplan: row.monthlyplan.map(({ id, budget_plan, ...rest }) => ({ ...rest })), // Exclude `id` from monthlyplan
    }));      
    
      try {
        const response = await api.post("/api/v1/budgets/create-plan/", preparedRows);
    
        if (response.status === 201) {
          const savedRows = response.data;
          setCaptionPlan((prev) => [
            ...prev.filter((item) => !item.isNew), // Remove rows that have `isNew`
            ...savedRows, // Add the saved rows
          ]);
        } else {
          alert("Ошибка при сохранении.");
        }
      } catch (error) {
        console.error("Error saving new rows:", error);
        alert("Ошибка при сохранении.");
      }
    };
    

    const enableEditing = (itemId, monthId) => {
      setCaptionPlan((prevCaptionPlan) =>
        prevCaptionPlan.map((item) =>
          item.id === itemId
            ? {
                ...item,
                monthlyplan: item.monthlyplan.map((month) =>
                  month.id === monthId ? { ...month, isEditing: true } : month
                ),
              }
            : item
        )
      );
    };

    const handlePlanSumChange = (itemId, monthId, newValue) => {
      setCaptionPlan((prevCaptionPlan) =>
        prevCaptionPlan.map((item) =>
          item.id === itemId
            ? {
                ...item,
                monthlyplan: item.monthlyplan.map((month) =>
                  month.id === monthId ? { ...month, plan_sum: Number(newValue) } : month
                ),
              }
            : item
        )
      );
    };

    const savePlanSum = async (itemId, monthId) => {
      const updatedItem = captionPlan.find((item) => item.id === itemId);
      const updatedMonth = updatedItem?.monthlyplan.find((month) => month.id === monthId);
    
      if (updatedMonth) {
        try {
          await api.patch(`/api/v1/budgets/update-monthlyplan/${monthId}/`, { plan_sum: updatedMonth.plan_sum });
          console.log("Plan sum updated successfully");
        } catch (error) {
          console.error("Error updating plan sum:", error);
        } finally {
          // Exit edit mode
          setCaptionPlan((prevCaptionPlan) =>
            prevCaptionPlan.map((item) =>
              item.id === itemId
                ? {
                    ...item,
                    monthlyplan: item.monthlyplan.map((month) =>
                      month.id === monthId ? { ...month, isEditing: false } : month
                    ),
                  }
                : item
            )
          );
        }
      }
    };
    

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="budgets-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3"><h3>Бюджетирование</h3></div>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="filter row flex-fill justify-content-center border rounded p-3">
                                        <div className="col">
                                            <label className="form-label">Проект</label>
                                            <APIMultipleSearchableSelect
                                                endpoint="/api/v1/organization/projects/search/"
                                                placeholder="Выберите проект"
                                                value={projects}
                                                onChange={(selected) => setProjects(selected)}
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Период активности проекта</label>    
                                            <div className="d-flex justify-content-center align-items-center align-self-center">
                                                <input 
                                                  type="date" 
                                                  className='form-control form-control-sm' 
                                                  name="date_from"
                                                  value={dateFrom}
                                                  onChange={(e) => setDateFrom(e.target.value)} />
                                                —
                                                <input 
                                                  type="date" 
                                                  className='form-control form-control-sm' 
                                                  name="date_to" 
                                                  value={dateTo}
                                                  onChange={(e) => setDateTo(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col d-flex ms-auto align-items-end">
                                            <button 
                                              className="btn btn-secondary me-3"
                                              onClick={() => {
                                                setProjects([]);
                                                setDateFrom("");
                                                setDateTo("");
                                                setData([]);}}>
                                                Сбросить
                                            </button>
                                            <button className="btn btn-primary" onClick={handleSearch}>Поиск</button>
                                        </div>
                                    </div>                               
                                    <div className="ms-4">
                                        <button className='btn btn-primary' data-bs-toggle="dropdown">Действие</button>
                                        <ul class="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" onClick={handleEditClick}>Редактировать бюджет</a>
                                                <a className="dropdown-item">Открыть редактирование</a>
                                                <a className="dropdown-item">Закрыть редактирование</a>
                                                <a className="dropdown-item">Поменять ответсвенного</a>
                                                <a className="dropdown-item">Архивировать бюджет</a>
                                                <a className="dropdown-item">Разархивировать бюджет</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {isEditing && <div>
                                    <button className='btn btn-success mb-2' onClick={handleEditClick}>
                                      {isEditing ? 'Закончить редактирование' : 'Редактировать бюджет'}
                                    </button>
                                  </div>}
                                {loading ? (
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>
                                ) : (
                                <div className="table-responsive table-container" style={{ height: '75vh', overflowY: 'scroll' }}>
                                <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th rowSpan="2">Статьи</th>
                                        <th colSpan="3">План итого</th>
                                        {months.map(month => (
                                        <th key={month} colSpan="3">{month}</th>
                                        ))}
                                    </tr>
                                    <tr>
                                        <th>План</th>
                                        <th>Факт</th>
                                        <th>Отклонение</th>
                                        {months.map(month => (
                                        <React.Fragment key={`header-${month}`}>
                                            <th>План</th>
                                            <th>Факт</th>
                                            <th>Отклонение</th>
                                        </React.Fragment>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.length > 0 ? (
                                        data.map((group) => (
                                            <React.Fragment key={group.type_of_caption_id}>
                                                {group.captions.map((item) => (
                                                    <TableRow
                                                        key={item.caption_id}
                                                        item={item}
                                                        months={months}
                                                        isEditing={isEditing}
                                                        openCaption={openPanel}
                                                        typeName={item === group.captions[0] ? group.type_of_caption_name : null} // Show only once per group
                                                    />
                                                ))}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                      <tr>
                                        <td colSpan="100%" className="text-center">
                                          Нет данных
                                        </td>
                                      </tr>
                                    )}
                                    </tbody>
                                </table>
                                </div>)}
                            </div>
                            <SlidingPanel isOpen={isOpen} onClose={closePanel}>
                              <div className=''>
                                <div className=' mb-4'>
                                  <div className='d-flex justify-content-center'>
                                    <p className='fs-2'>{captionPlan[0]?.caption.name}</p>
                                  </div>
                                  <button className='btn btn-primary'  onClick={handleAddRow}>Заполнить диапазон</button>
                                </div>
                                <div className='caption-table-container'>
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr className="table-light">
                                        <th rowSpan={2}>№</th>
                                        <th rowSpan={2}>Организация</th>
                                        <th rowSpan={2}>Контрагент</th>
                                        <th rowSpan={2}>Итого</th>
                                        {captionPlan[0]?.monthlyplan?.map((month) => (
                                          <React.Fragment key={month.id}>
                                            <th colSpan={2}>{month?.period_str}</th>
                                          </React.Fragment>
                                        ))}
                                      </tr>
                                      <tr className="table-light">
                                      {captionPlan[0]?.monthlyplan?.map((_, index) => (
                                          <React.Fragment key={index}>
                                            <th>План</th>
                                            <th>Факт</th>
                                          </React.Fragment>
                                        ))}
                                      </tr>
                                      <tr>
                                        <th colSpan={3}>Распределения</th>
                                        <th>30/100</th>
                                        {captionPlan[0]?.monthlyplan?.map((month, index) => (
                                          <th colSpan={2} key={index}>
                                            {month.percent}%
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {captionPlan.map((item, index) => (
                                        <tr key={item.id}>
                                          <td>{index+1}</td>
                                          <td>{item?.company?.name}</td>
                                          <td>
                                            {item.isNew ? (
                                              <APISearchableSelect
                                                endpoint="/api/v1/bills/counterparty/search/"
                                                size="sm"
                                                value={item?.counterparty}
                                                onChange={(value) => handleChange(item.id,  { name: "counterparty", value } )}
                                                placeholder="Выберите контрагента"
                                              />
                                            ) : (
                                              item?.counterparty?.name
                                            )}
                                          </td>
                                          <td>{Number(item.total).toLocaleString()}</td>
                                          {item.monthlyplan.map((month, idx) => (
                                            <React.Fragment key={idx}>
                                              <td>
                                                {month.isEditing ? (
                                                  <input
                                                    type="number"
                                                    className="form-control"
                                                    value={month.plan_sum || 0}
                                                    onChange={(e) => handlePlanSumChange(item.id, month.id, e.target.value)}
                                                    onBlur={() => savePlanSum(item.id, month.id)}
                                                    onKeyDown={(e) => {
                                                      if (e.key === "Enter") savePlanSum(item.id, month.id);
                                                    }}
                                                    autoFocus
                                                  />
                                                ) : (
                                                  <span onDoubleClick={() => enableEditing(item.id, month.id)}>
                                                    {Number(month.plan_sum || 0).toLocaleString()}
                                                  </span>
                                                )}
                                              </td>
                                              <td>{Number(month.fact_sum ? month.fact_sum : 0).toLocaleString()}</td>
                                            </React.Fragment>
                                          ))}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="d-flex justify-content-end mt-3">
                                  {captionPlan.some(plan => plan.isNew) && (
                                    <button className="btn btn-success" onClick={handleSaveAllRows}>
                                      Сохранить
                                    </button>
                                  )}
                                </div>
                              </div>
                            </SlidingPanel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default BudgetPlanPage