import React, { useState, useEffect, useRef, useCallback } from 'react';
import api from '../../api';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import ProtectedContent from '../ProtectedContent';
import { useAuth } from "../../AuthContext";
import SearchableSelect from '../payments/SearchableSelect'; 


const Counterparties = () => {
    const navigate = useNavigate()
    const { permissions, user } = useAuth();
    const [counterparties, setCounterparties] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedCounterparties, setCheckedCounterparties] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [iinbinFilter, setinnbinFilter] = useState('');
    const [organFilter, setSelectedOrganization] = useState('');
    const [archivedFilterNotArchive, setArchivedFilterNotActive] = useState(false); //yes
    const [archivedFilterActive, setArchivedFilterActive] = useState(false);  //no
    const searchIDofelem = location.search.replace("?","");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = {
        name: '',
        bin_iin: '',
        code: '',
        company: '',
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const fetchCounterparties = async () => {
        setLoading(true);
        try {
            console.log(location)
            if (searchIDofelem){
                const response = await api.post('/api/v1/bills/counterpartyfilter/', {
                    id:searchIDofelem,
                    is_active : [true,false]
                });
                setCounterparties(response.data);
            }else{
                const response = await api.get(`api/v1/bills/counterparty/?limit=${limit}&offset=${offset}`);
             setCounterparties(prevCounterparties => {
                const newCounterparties = response.data.results.filter(newCounterparties => 
                    !prevCounterparties.some(prevCounterparties => prevCounterparties.id === newCounterparties.id)
                );
                return [...prevCounterparties, ...newCounterparties];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
            }
           
        } catch (error) {
            console.error('Error fetching bank accounts', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error);
        }
    };
    useEffect(() => {
        fetchCompanies();
    }, []); 
    
    useEffect(() => {
        setCounterparties([]); // Clear existing counterparties
        setOffset(0); // Reset offset
        setHasMore(true);
        fetchCounterparties();
    }, [location]);

    useEffect(() => {
        const loadMore = async () => {
            if (hasMore && !loading && offset > 0) {
                setLoading(true);
                try {
                    await fetchCounterparties();
                } catch (error) {
                    console.error('Error fetching data', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        
        loadMore();
    }, [offset, hasMore]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchCounterpartiesById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post('/api/v1/bills/counterpartyfilter/', {
                        id:searchIDofelem,
                        is_active : [true,false]
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setCounterparties(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching caption by ID', error);
                }
            } else {
                setNameFilter('');
                setCounterparties([]);
                setOffset(0);
                setHasMore(true);
                fetchCounterparties();
            }
        };

        fetchCounterpartiesById();
    }, [searchIDofelem]);


    const handleUpdateCounterparties = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedCounterparties.map(id => 
                api.patch(`api/v1/bills/counterparty/${id}/update`, { is_active: isActive })
            ));
            // Refresh the list after updating
            setCounterparties([]);
            fetchCounterparties();
            // setCompanies([]);
            setOffset(0);
            setHasMore(true);
            setCheckedCounterparties([])
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} Counterparties`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveCounterparties = () => handleUpdateCounterparties(false);
    const handleUnarchiveCounterparties = () => handleUpdateCounterparties(true);
    

    const handleCheckboxChange = (counterpartyId) => {
        setCheckedCounterparties(prevcounterparties => {
            if (prevcounterparties.includes(counterpartyId)) {
                return prevcounterparties.filter(id => id !== counterpartyId);
            } else {
                return [...prevcounterparties, counterpartyId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allcounterparties = counterparties.map(counterparty => counterparty.id);
            setCheckedCounterparties(allcounterparties);
        } else {
            setCheckedCounterparties([]);
        }
    };

    // if (loading) {
    //     return (
    //         <div className="position-absolute top-50 start-50 translate-middle">
    //             <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    //         </div>);
    // }
    const allChecked = counterparties.length > 0 && counterparties.every(counterparty => checkedCounterparties.includes(counterparty.id));

    const handleReset = () => {
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();

    };
    const handleSearch = async () => {
        try {
            const response = await api.post('/api/v1/bills/counterpartyfilter/', {
                name: nameFilter.toLowerCase(), 
                iinbin: iinbinFilter,
                is_active : [!archivedFilterNotArchive,archivedFilterActive],
                company : organFilter
            });
            console.log('Counterparties fetched:', response.data); 
            setCounterparties(response.data); 
        } catch (error) {
            console.error('Error fetching Counterparties:', error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ['name', 'bin_iin', 'company'];
        const errors = requiredFields.reduce((acc, key) => {
            if (!formData[key]) {
                if (key === 'company') {
                    acc[key] = 'Выберите значение';
                } else {
                    acc[key] = 'Заполните поле';
                }
            }
            return acc;
        }, {});
      
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
          }
        setLoading(true);
        try {
            if (isEditing) {
                const response = await api.patch(`/api/v1/bills/counterparty/${formData.id}/update/`, formData);
                console.log('Update Response:', response.data);
            } else {
                const response = await api.post('api/v1/bills/counterparty-create/', formData);
                console.log('Create Response:', response.data);
            }
            setCounterparties([]);
            setOffset(0);
            handleCloseModal();
            fetchCounterparties();
        } catch (error) {
            alert('Заполните все поля');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };
    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const handleRowClick = (counterparty) => {
        if (!permissions.includes('payment.change_counterparty') && !user.is_superuser) {
            return;
        }
        setIsEditing(true);
        setFormData({
            id: counterparty.id,
            name: counterparty.name,
            bin_iin: counterparty.bin_iin || '',
            code: counterparty.code || '',
            company: counterparty.company ? companies.find(company => company.id === counterparty.company)?.name : '',
        });
        setShowModal(true);
    };
    const handleCompanyChange = (value) => {
        setFormData(prev => ({
            ...prev,
            company: value
        }));
        if (errors.company) {
            setErrors(prev => ({ ...prev, company: '' }));
        }
    };

    return (
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Контрагенты</h3></div>
                                    <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование контрагента</label>
                                            <input
                                                type="text"
                                                placeholder="Введите контрагента"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div  className="col">
                                            <label className='form-label'>БИН/ИИН</label>
                                            <input
                                                type="text"
                                                placeholder="Введите БИН/ИИН"
                                                value={iinbinFilter}
                                                onChange={e => setinnbinFilter(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Организация</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="/api/v1/organization/search/"
                                            value={organFilter}
                                            onChange={(value) => setSelectedOrganization(value)}
                                            placeholder="Выберите организацию"
                                        />
                                        </div>
                                        <div className="col">
                                            <label>Архивный</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNotActive"
                                                    checked={archivedFilterNotArchive}
                                                    onChange={e => setArchivedFilterNotActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckActive"
                                                    checked={archivedFilterActive}
                                                    onChange={e => setArchivedFilterActive(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckActive">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <ProtectedContent perms={'payment.change_counterparty'}>
                                            <button 
                                                onClick={handleArchiveCounterparties} 
                                                className="btn btn-secondary me-2"
                                                disabled={checkedCounterparties.length === 0}
                                            >
                                                Архивировать выбранные
                                            </button>
                                            <button 
                                                onClick={handleUnarchiveCounterparties} 
                                                className="btn btn-success me-2"
                                                disabled={checkedCounterparties.length === 0}
                                            >
                                                Разархивировать выбранные
                                            </button>
                                        </ProtectedContent>
                                        <ProtectedContent perms={'payment.add_counterparty'}>
                                            <button className="btn btn-primary me-2" onClick={() => setShowModal(true)}>
                                                Добавить
                                            </button>
                                        </ProtectedContent>
                                        <button onClick={fetchCounterparties} className="btn btn-primary">Обновить</button>
                                        
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '55vh', overflowY: 'scroll' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input 
                                                    type="checkbox" 
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th>Наименование контрагента</th>
                                            <th>БИН/ИИН</th>
                                            <th>Организация</th>
                                            <th>Архивный</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {counterparties.map((counterparty) => (
                                            <tr key={counterparty.name} onDoubleClick={() => handleRowClick(counterparty)}>
                                                <td>
                                                    <input 
                                                        type="checkbox"
                                                        checked={checkedCounterparties.includes(counterparty.id)}
                                                        onChange={() => handleCheckboxChange(counterparty.id)}
                                                    />
                                                </td>
                                                <td style={{ 
                                                    width: '300px', 
                                                    maxWidth: '300px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>
                                                    {counterparty.name}
                                                </td>
                                                <td>{counterparty.bin_iin}</td>
                                                <td style={{ 
                                                    width: '300px', 
                                                    maxWidth: '300px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{counterparty.company ? companies.find(company => company.id === counterparty.company)?.name : ''}</td>
                                                <td>{counterparty.is_active ? 'Нет' : 'Да'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-center position-relative">
                            <h5 className="modal-title">
                                {isEditing ? 'Редактирование контрагента' : 'Создание контрагента'}
                            </h5>
                        </div>
                            <div className="modal-body">
                                <form className="bankaccount-form" onSubmit={handleSubmit}>
                                    <div className='form_input'>
                                            <div className="row g-1">
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Наименование контрагента*</label>
                                                    <input 
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                        type="text" 
                                                        name="name" 
                                                        value={formData.name}
                                                        placeholder='Введите наименование' 
                                                        onChange={handleChange}
                                                    />
                                                    {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                                                </div>
                                                <div className='col-md-12'>
                                                    <label className='form-label'>БИН/ИИН*</label>
                                                    <input 
                                                        className={`form-control ${errors.bin_iin ? 'is-invalid' : ''}`}
                                                        type="text" 
                                                        name="bin_iin" 
                                                        value={formData.bin_iin}
                                                        placeholder='Введите БИН/ИИН' 
                                                        onChange={handleChange}
                                                    />
                                                    {errors.bin_iin && <span className="invalid-feedback">{errors.bin_iin}</span>}
                                                </div>
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Организация*</label>
                                                    <SearchableSelect
                                                        endpoint="/api/v1/organization/"
                                                        value={formData.company || ''}
                                                        onChange={handleCompanyChange}
                                                        placeholder="Выберите организацию"
                                                        className={errors.company ? 'is-invalid' : ''}
                                                        isClearable={true}
                                                        isMulti={false}
                                                    />
                                                    {errors.company && (
                                                        <div className="invalid-feedback" style={{display: 'block'}}>
                                                            {errors.company}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-12">
                                                    <label className='form-label'>Код</label>
                                                    <input 
                                                        className="form-control" 
                                                        type="text" 
                                                        name="code"
                                                        value={formData.code} 
                                                        onChange={handleChange} 
                                                        placeholder='Введите код'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer d-flex justify-content-center position-relative">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    Закрыть
                                </button>
                                <button className="btn btn-primary" onClick={handleSubmit}>
                                {isEditing ? 'Сохранить' : 'Создать'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Counterparties;
