import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "../styles/homePageStyle.css";
import { Link } from 'react-router-dom';
import SideBar from "./SideBar";
import Header from "./Header";
import IntegrationContent from "./Integrations";
import SchemeFinmodel from "./SchemeFinmodel"
import UserList from "./UserList"
import RolesTable from "./roles/RolesTable";
import ProtectedContent from './ProtectedContent';

function Finmodel() {
    const [activeSection, setActiveSection] = useState("finmodel");

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
            <SideBar/> 
                <div className="col py-3">
                <h3>Создание финансовой модели</h3>
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid">
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link className={`nav-link ${activeSection === "finmodel" ? "active" : ""}`} to="#" onClick={() => setActiveSection("finmodel")}>Схема фин.модели</Link>
                                    </li>
                                    <ProtectedContent perms={'connection.view_connection'}>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${activeSection === "integration" ? "active" : ""}`} to="#" onClick={() => setActiveSection("integration")}>Интеграции</Link>
                                        </li>
                                    </ProtectedContent>
                                    <ProtectedContent perms={'users.view_user'}>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${activeSection === "users" ? "active" : ""}`} to="#" onClick={() => setActiveSection("users")}>Пользователи</Link>
                                    </li>
                                    </ProtectedContent>
                                    <ProtectedContent perms={'auth.view_group'}>
                                        <li className="nav-item">
                                            <Link className={`nav-link ${activeSection === "roles" ? "active" : ""}`} to="#" onClick={() => setActiveSection("roles")}>Группы доступов</Link>
                                        </li>
                                    </ProtectedContent>
                                    {/* <li className="nav-item">
                                        <Link className={`nav-link ${activeSection === "reports" ? "active" : ""}`} to="#" onClick={() => setActiveSection("reports")}>Регулярные отчеты</Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <div>
                        {activeSection === "finmodel" && <SchemeFinmodel />}
                        {activeSection === "integration" && <IntegrationContent />}
                        {activeSection === "users" && <UserList />}
                        {activeSection === "roles" && <RolesTable />}
                        {activeSection === "reports" && <div>Регулярные отчеты Page Content</div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Finmodel;
