import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import api from "../../api";
import SideBar from "../SideBar";
import Header from "../Header";

const ProjectEdit = () => {
    const { id } = useParams();
    const [project, setProject] = useState({});
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);

    const navigate = useNavigate()

    const fetchProject = async () => {
        try {
            const response = await api.get(`/api/v1/organization/projects/${id}/`);
            setProject(response.data);
        } catch (error) {
            console.error('Error fetching bank account', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }

    const fetchGroups= async () => {
        try {
            const response = await api.get('/api/v1/organization/groups/');
            setGroups(response.data);
        } catch (error) {
            console.error('Error fetching banks', error)
        }
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchProject(),
                fetchCompanies(),
                fetchGroups(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]); // Ensure it runs only once when the component mounts

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProject((prevProject) => ({
            ...prevProject,
            [name]: value,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const preparedProject = {
                ...project,
            };

            const response = await api.patch(`/api/v1/organization/projects/${id}/update/`, preparedProject);


            const updatedProject = await response.data;
            console.log('Updated Project:', updatedProject);
            navigate('/projects')
        } catch (error) {
            console.error('Error updating Project:', error);
        }
    };

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <h2>Редактировать проект</h2>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Наименование проекта</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={project.name || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Полное наименование проекта</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="full_name"
                                    value={project.full_name || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Описание</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    value={project.description || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Организация</label>
                                <select
                                        className="form-select"
                                        name="company"
                                        value={project.company || ''}
                                        onChange={handleChange}>
                                        <option value="">
                                            {project.company ? companies.find(company => company.id === project.company)?.name : 'Выберите организацию...'}
                                        </option>
                                        {companies.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Родительская группа</label>
                                <select
                                        className="form-select"
                                        name="finmodel"
                                        value={project.finmodel || ''}
                                        onChange={handleChange}>
                                        <option value="">
                                            {project.finmodel ? groups.find(group => group.id === project.finmodel)?.name : 'Выберите родительскую группу...'}
                                        </option>
                                        {groups.map((group) => (
                                            <option key={group.id} value={group.id}>
                                                {group.name}
                                            </option>
                                        ))}
                                    </select>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Сохранить
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProjectEdit;
