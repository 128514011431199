import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useState, useEffect } from "react";
import api from "../api";
import "../styles/finmodel.css";
import ProtectedContent from './ProtectedContent';

const GroupTable = () => {

    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newGroupName, setNewGroupName] = useState("");
    const [parentGroupId, setParentGroupId] = useState("");
    const [selectedManager, setSelectedManager] = useState("");
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [isMainGroup, setIsMainGroup] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const fetchUsers = async () => {
        try {
            const response = await api.get('/api/v1/users/');
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users', error);
        }
    };

    const fetchGroups = async () => {
        try {
            const response = await api.get('/api/v1/organization/groups/');
            const groupsData = response.data;
    
            const mainGroup = groupsData.find(group => group.parentId === null);
    
            // Если главной группы нет, создаем её
            if (!mainGroup) {
                const newGroup = {
                    "name": "Главная группа",
                    "parentId": null
                };
                const createResponse = await api.post('/api/v1/organization/groups/create/', newGroup, {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                });
                setGroups([createResponse.data]);
                return [createResponse.data];
            } else {
                setGroups(groupsData);
                return groupsData;
            }
        } catch (error) {
            console.error('Error fetching groups', error);
            return [];
        }
    };
    

    useEffect(() => {
        fetchGroups();
        fetchUsers();
    }, []);

    const buildTree = (nodes, parentId = null) => {
        return nodes
            .filter(node => node.parentId === parentId)
            .map(node => ({ ...node, children: buildTree(nodes, node.id) }));
    };

    const tree = buildTree(groups);

    const formatUsersArray = (managerId, employeeIds) => {
        const users = [];
        if (managerId) {
            users.push({ id: managerId });
        }
        employeeIds.forEach(id => {
            users.push({ id });
        });
        return users;
    };

    const handleAddGroup = async () => {
        try {
            const newGroup = {
                name: newGroupName,
                parentId: parentGroupId,
                user: formatUsersArray(selectedManager, selectedEmployees)
            };
            const response = await api.post('/api/v1/organization/groups/create/', newGroup, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // Обновляем список групп после добавления новой группы
            setGroups(prevGroups => [...prevGroups, response.data]);

            // Сбрасываем состояние модального окна и формы
            resetForm();
            setShowModal(false);
        } catch (error) {
            console.error('Error creating group', error);
        }
    };
    const handleDeleteGroup = async () => {
        if (groupToDelete) {
            setIsDeleting(true);
            try {
                await api.delete(`/api/v1/organization/groups/${groupToDelete.id}/delete/`);
                
                await fetchGroups();
                
                setShowDeleteModal(false);
                setGroupToDelete(null);
            } catch (error) {
                console.error('Error deleting group', error);
            } finally {
                setIsDeleting(false);
            }
        }
    };

    const handleEditGroup = async () => {
        try {
            const updatedGroup = {
                name: newGroupName,
                parentId: parentGroupId,
                user: formatUsersArray(selectedManager, selectedEmployees)
            };
            const response = await api.put(`/api/v1/organization/groups/update/${selectedGroupId}/`, updatedGroup, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // Обновляем список групп после обновления группы
            setGroups(prevGroups => prevGroups.map(group => group.id === selectedGroupId ? response.data : group));

            // Сбрасываем состояние модального окна и формы
            resetForm();
            setShowModal(false);
        } catch (error) {
            console.error('Error updating group', error);
        }
    };

    const handleOpenModal = (group, isEdit = false) => {
        setSelectedGroupId(isEdit ? group.id : null);
        setNewGroupName(isEdit ? group.name : "");
        setParentGroupId(isEdit ? (group.parentId || "") : group.id);
        setSelectedManager(isEdit ? (group.user[0] ? group.user[0].id : "") : "");
        setSelectedEmployees(isEdit ? group.user.slice(1).map(user => user.id) : []);
        setIsMainGroup(group.parentId === null);
        setShowModal(true);
    };
    const handleEmployeeChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setSelectedEmployees(prev =>
            e.target.checked ? [...prev, value] : prev.filter(id => id !== value)
        );
    };
    const resetForm = () => {
        setNewGroupName("");
        setParentGroupId("");
        setSelectedManager("");
        setSelectedEmployees([]);
        setSelectedGroupId(null);
        setIsMainGroup(false);
    };

    const mainGroup = groups.find(group => group.parentId === null);
    const filteredUsers = users.filter(user => !selectedEmployees.includes(user.id));

    return (
        <div className="container-fluid">
            <h2 className="text-center">{mainGroup ? mainGroup.name : "Главная группа"}</h2>
            <div className="tree-container">
                <div className="tree-wrapper">
                {tree.map(rootNode => (
                    <TreeNode key={rootNode.id} node={rootNode} users={users} setShowModal={setShowModal} handleOpenModal={handleOpenModal} setShowDeleteModal={setShowDeleteModal} setGroupToDelete={setGroupToDelete}/>
                ))}
                </div>
            </div>

            <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{selectedGroupId ? "Редактировать подразделение" : "Добавить подразделение"}</h5>
                            <button type="button" className="btn-close" onClick={() => { resetForm(); setShowModal(false); }}></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="groupName" className="form-label">Наименование подразделения</label>
                                    <input type="text" className="form-control" id="groupName" value={newGroupName} onChange={(e) => setNewGroupName(e.target.value)} />
                                </div>
                                {!isMainGroup && (
                                        <div className="mb-3">
                                            <label htmlFor="parentGroup" className="form-label">Вышестоящее подразделение</label>
                                            <select className="form-select" id="parentGroup" value={parentGroupId} onChange={(e) => setParentGroupId(e.target.value)}>
                                                <option value="">Выберите подразделение</option>
                                                {groups
                                                    .filter(group => group.id !== selectedGroupId)
                                                    .map(group => (
                                                        <option key={group.id} value={group.id}>{group.name}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    )}
                                <div className="mb-3">
                                    <label htmlFor="manager" className="form-label">Руководитель</label>
                                    <select className="form-select" id="manager" value={selectedManager} onChange={(e) => setSelectedManager(e.target.value)}>
                                        <option value="">Выберите руководителя</option>
                                        {filteredUsers.map(user => (
                                            <option key={user.id} value={user.id}>{user.first_name + ' ' + user.last_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Сотрудники</label>
                                    {users.filter(user => user.id !== selectedManager).map(user => (
                                        <div key={user.id} className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`employee-${user.id}`}
                                                value={user.id}
                                                checked={selectedEmployees.includes(user.id)}
                                                onChange={handleEmployeeChange}
                                            />
                                            <label className="form-check-label" htmlFor={`employee-${user.id}`}>
                                                {user.first_name + ' ' + user.last_name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => { resetForm(); setShowModal(false); }}>Закрыть</button>
                            <button type="button" className="btn btn-primary" onClick={selectedGroupId ? handleEditGroup : handleAddGroup}>{selectedGroupId ? "Сохранить" : "Добавить"}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal ${showDeleteModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
                <div className="modal-dialog">
                <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Подтверждение удаления</h5>
                <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
            </div>
            <div className="modal-body">
                <p>Вы уверены, что хотите удалить группу "{groupToDelete?.name}"?</p>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowDeleteModal(false)} disabled={isDeleting}>Отмена</button>
                <button type="button" className="btn btn-danger" onClick={handleDeleteGroup} disabled={isDeleting}>
                {isDeleting ? (
                <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Удаление...
                </>
                ) : 'Удалить'}
                </button>
                </div>
                </div>
            </div>
        </div>
        </div>
    );
};

// Компонент TreeNode для отображения узла дерева
const TreeNode = ({ node, users, setShowModal, handleOpenModal, setShowDeleteModal, setGroupToDelete }) => {
    const user = users.find(u => u.id === (node.user[0] && node.user[0].id));
    
    return (
        <div className="tree-node">
            <div className="card mb-3" style={{ width: '300px' }}>
                <div className="card-header d-flex justify-content-between align-items-center">
                    {node.name}
                    <div>
                        <ProtectedContent perms={'organization.change_finmodel'}>
                            <button className="btn btn-light me-1" onClick={() => handleOpenModal(node, true)}>
                                <i className="bi bi-pencil"></i>
                            </button>
                        </ProtectedContent>
                        <ProtectedContent perms={'organization.add_finmodel'}>
                            <button className="btn btn-light me-1" onClick={() => handleOpenModal(node)}>
                                <i className="bi bi-plus-circle"></i>
                            </button>
                        </ProtectedContent>
                        {node.parentId !== null && (
                            <button className="btn btn-light" onClick={() => {
                                setGroupToDelete(node);
                                setShowDeleteModal(true);
                            }}>
                                <i className="bi bi-trash"></i>
                            </button>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <div className="mb-1">
                        <div>
                            {user ? (
                                <p><i className="bi bi-person-circle me-2"></i>{user.first_name + ' ' + user.last_name}</p>
                            ) : (
                                <p>Нет руководителя</p>
                            )}
                        </div>
                    </div>
                    <div>
                        <p>Сотрудники</p>
                        {node.user.length > 1 ? (
                            <ul>
                                {node.user.slice(1).map(userObj => {
                                    const user = users.find(u => u.id === userObj.id);
                                    return user ? <p key={user.id}><i className="bi bi-person-circle me-2"></i>{user.first_name + ' ' + user.last_name}</p> : null;
                                })}
                            </ul>
                        ) : (
                            <p>Нет сотрудников</p>
                        )}
                    </div>
                </div>
            </div>
            {node.children && (
                <div className="tree-children">
                    {node.children.map(child => (
                        <TreeNode key={child.id} node={child} users={users} setShowModal={setShowModal} handleOpenModal={handleOpenModal} setShowDeleteModal={setShowDeleteModal} setGroupToDelete={setGroupToDelete}/>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GroupTable;
