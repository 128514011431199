import React, { useState, useEffect, useRef } from 'react';
import api from '../../api';
import "../../styles/searchSelect.css";

const APISearchableSelect = ({ endpoint, value, onChange, placeholder, displayKey = 'name', valueKey = 'id', disabled = false, size = 'sm' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const searchOptions = async (term) => {
    setLoading(true);
    try {
      const response = await api.get(`${endpoint}${endpoint.includes('?') ? '&' : '?'}search=${term}`);
      setOptions(response.data);
    } catch (error) {
      console.error('Error searching options:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        searchOptions(searchTerm);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else if (isOpen) {
      searchOptions('');
    } else {
      setOptions([]);
    }
  }, [searchTerm, isOpen]);

  const handleSelect = (option) => {
    if (selectedOption!= null && JSON.stringify(selectedOption) === JSON.stringify(option)) {
        setSelectedOption('');  
        onChange('');  
        setIsOpen(false);  
    } else {
        setSelectedOption(option); 
        onChange(option[valueKey]);  
        setIsOpen(false); 
        setSearchTerm(''); 
    }
};
  const handleToggleDropdown = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      searchOptions(''); // Fetch options when the dropdown is opened
    }
  };

  return (
    <div className="searchable-select" ref={wrapperRef}>
      <div 
        className={`form-select form-select-${size} d-flex justify-content-between align-items-center ${disabled ? 'disabled' : ''}`} 
        onClick={disabled ? null : handleToggleDropdown}
      >
        <span className='options'>{selectedOption ? selectedOption[displayKey] : (value ? value[displayKey] : placeholder)}</span>
      </div>
      {isOpen && (
        <div className="select-dropdown">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
          {loading ? (
            <div className="text-center">Loading...</div>
          ) : (
            <ul className="list-group">
            {options.map((option) => (
                <li
                    key={option[valueKey]}
                    className="list-group-item"
                    onClick={() => handleSelect(option)}
                >
                    {option[displayKey]}
                    {selectedOption && JSON.stringify(selectedOption) === JSON.stringify(option) && (
                        <span className="red-x">✖</span> 
                    )}
                </li>
            ))}
        </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default APISearchableSelect;
