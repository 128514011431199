import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import api from "../../api";
import SideBar from "../SideBar";
import Header from "../Header";

const BankAccountEdit = () => {
    const { id } = useParams();
    const [bankaccount, setBankaccount] = useState({});
    const [companies, setCompanies] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [counterparties, setCounterparties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [banks, setBank] = useState([]);

    const navigate = useNavigate()

    const fetchBankAccount = async () => {
        try {
            const response = await api.get(`api/v1/bank/bankaccounts/${id}/`);
            setBankaccount(response.data);
        } catch (error) {
            console.error('Error fetching bank account', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }
    const fetchCurrency = async () => {
        try {
            const response = await api.get('/api/v1/bank/currencies/');
            setCurrencies(response.data);
        } catch (error) {
            console.error('Error fetching currencies', error)
        }
    }

    const fetchCounterparty = async () => {
        try {
            const res = await api.get('api/v1/bills/counterparty/');
            setCounterparties(res.data);
        } catch (error) {
            console.error("Error fetching counterpaties", error)
        }
    }

    const fetchBank = async () => {
        try {
            const response = await api.get('/api/v1/bank/');
            setBank(response.data);
        } catch (error) {
            console.error('Error fetching banks', error)
        }
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([
                fetchBankAccount(),
                fetchCompanies(),
                fetchCounterparty(),
                fetchCurrency(),
                fetchBank(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]); // Ensure it runs only once when the component mounts

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBankaccount((prevBankAccount) => ({
            ...prevBankAccount,
            [name]: value,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const preparedBankAccount = {
                ...bankaccount,
            };

            const response = await api.patch(`/api/v1/bank/bankaccounts/${id}/update`, preparedBankAccount);


            const updatedBankAccount = await response.data;
            console.log('Updated BankAccount:', updatedBankAccount);
            navigate('/bankacc')
        } catch (error) {
            console.error('Error updating BankAccount:', error);
        }
    };

    return (
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar />
                <div className="col py-3">
                    <h2>Редактировать банковский счет</h2>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Название банковского счета</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={bankaccount.name || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Номер банковского счета</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="account_number"
                                    value={bankaccount.account_number || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Организация</label>
                                <select
                                        className="form-select"
                                        name="company"
                                        value={bankaccount.company || ''}
                                        onChange={handleChange}>
                                        <option value="">
                                            {bankaccount.company ? companies.find(company => company.id === bankaccount.company)?.name : 'Выберите организацию...'}
                                        </option>
                                        {companies.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))}
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Код</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="code"
                                    value={bankaccount.code || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Вид счета</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="account_type"
                                    value={bankaccount.account_type || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Банк</label>
                                <select
                                        className="form-select"
                                        name="bank"
                                        value={bankaccount.bank || ''}
                                        onChange={handleChange}>
                                        <option value="">
                                            {bankaccount.bank ? banks.find(bank => bank.id === bankaccount.bank)?.name : 'Выберите банк...'}
                                        </option>
                                        {banks.map((bank) => (
                                            <option key={bank.id} value={bank.id}>
                                                {bank.name}
                                            </option>
                                        ))}
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Валюта</label>
                                <select
                                        className="form-select"
                                        name="currency"
                                        value={bankaccount.currency || ''}
                                        onChange={handleChange}>
                                        <option value="">
                                            {bankaccount.currency ? currencies.find(currency => currency.id === bankaccount.currency)?.name : 'Выберите валюту...'}
                                        </option>
                                        {currencies.map((currency) => (
                                            <option key={currency.id} value={currency.id}>
                                                {currency.name}
                                            </option>
                                        ))}
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Владелец(Организация)</label>
                                <select
                                        className="form-select"
                                        name="owner_organization"
                                        value={bankaccount.owner_organization || ''}
                                        onChange={handleChange}>
                                        <option value="">
                                            {bankaccount.owner_organization ? companies.find(company => company.id === bankaccount.owner_organization)?.name : 'Выберите организацию...'}
                                        </option>
                                        {companies.map((owner_organization) => (
                                            <option key={owner_organization.id} value={owner_organization.id}>
                                                {owner_organization.name}
                                            </option>
                                        ))}
                                    </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Владелец(Контрагент)</label>
                                <select
                                        className="form-select"
                                        name="owner_counterparty"
                                        value={bankaccount.owner_counterparty || ''}
                                        onChange={handleChange}>
                                        <option value="">
                                            {bankaccount.owner_counterparty ? counterparties.find(counterparty => counterparty.id === bankaccount.owner_counterparty)?.name : 'Выберите контрагента...'}
                                        </option>
                                        {counterparties.map((owner_counterparty) => (
                                            <option key={owner_counterparty.id} value={owner_counterparty.id}>
                                                {owner_counterparty.name}
                                            </option>
                                        ))}
                                    </select>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Сохранить
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BankAccountEdit;
