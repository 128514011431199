import "../../styles/slidePanelStyle.css";
import { useRef, useEffect } from "react";


const SlidingPanel = ({ isOpen, onClose, children }) => {
    const panelRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (panelRef.current && !panelRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('pointerdown', handleClickOutside);
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.removeEventListener('pointerdown', handleClickOutside);
            document.body.classList.remove('no-scroll');
        };
    }, [isOpen, onClose]);

    return (
        <>
        <div className={`sliding-panel-backdrop ${isOpen ? "open" : ""}`}
            onClick={onClose}
        ></div>
        <div className={`sliding-panel ${isOpen ? "open" : ""}`}>
            <div ref={panelRef} className="sliding-panel-content">
                <button onClick={onClose} className="close-btn">
                    &times;
                </button>
                {children}
            </div>
        </div>
        </>
    );
};

export default SlidingPanel;