import { useState, useEffect } from "react";
import api from "../api";
import SideBar from "./SideBar";
import ReactLoading from 'react-loading';
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import useFetchData  from '../functions/UprData.jsx'

const CreateUserPage = () => {
    const [formData, setFormData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        phone_number: '',
        position: '',
        groups: [],
        password: '',
        confirmPassword: '',
    });
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const fetchGroups = useFetchData('api/v1/groups/', setGroups);

    useEffect(() => {
        try {
            setLoading(true);
            fetchGroups();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [])

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleGroupInput = (groupId, isChecked) => {
        setFormData(prevForm => {
            let updatedGroups;
            if (isChecked) {
                updatedGroups = [...prevForm.groups, groupId];
            } else {
                updatedGroups = prevForm.groups.filter(id => id !== groupId);
            }
            return {
                ...prevForm,
                groups: updatedGroups
            };
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Form validation
        const { username, first_name, last_name, phone_number, email, password, confirmPassword } = formData;
        const errors = {};
        if (!username) {
            errors.username = 'Username is required';
        }
        if (!email) {
            errors.email = 'Email is required';
        }
        if (!password) {
            errors.password = 'Password is required';
        }
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }
        if (!first_name) {
            errors.first_name = 'First name is required';
        }
        if (!last_name) {
            errors.last_name = 'Last name is required';
        }
        if (!phone_number) {
            errors.phone_number = 'Phone number is required'
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        // Submit the form
        try {
            setLoading(true);
            const response = await api.post('/api/v1/worker/', formData);
            console.log('Registration successful:', response.data);
            navigate('/finmodel')
        } catch (error) {
            console.error('Registration failed:', error.response.data);
            // Handle error (e.g., show error message)
        } finally {
            setLoading(false);
        }
    };
    if (loading) {
        return (
            <div className="position-absolute top-50 start-50 translate-middle">
                <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
            </div>);
    }


    return (
        <>
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col">
                    <div className="create-user-header mt-3">
                        <h2>Создание пользователя</h2>
                    </div>
                    <div className="create-user-content mt-5">
                        <form className="user-form" onSubmit={handleSubmit}>
                            <div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className='form-label'>Логин</label>
                                            <input className="form-control" type="text" name="username" placeholder='Введите ИИН' value={formData.username} onChange={handleInputChange} />
                                            {errors.username && <span>{errors.username}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Фамилия</label>
                                            <input className="form-control" type="text" name="first_name" placeholder='Введите фамилию' value={formData.first_name} onChange={handleInputChange} />
                                            {errors.first_name && <span>{errors.first_name}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Имя</label>
                                            <input className="form-control" type="text" name="last_name" placeholder='Введите имя' value={formData.last_name} onChange={handleInputChange} />
                                            {errors.last_name && <span>{errors.last_name}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-5">
                                            <label className="form-label">Отчество</label>
                                            <input className="form-control" type="text" name="middle_name" placeholder='Введите отчество' value={formData.middle_name} onChange={handleInputChange} />
                                            {errors.middle_name && <span>{errors.middle_name}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label">Номер телефона</label>
                                            <input className="form-control" type="text" name="phone_number" placeholder='+7 777 77 77 777' value={formData.phone_number} onChange={handleInputChange} />
                                            {errors.phone_number && <span>{errors.phone_number}</span>}
                                        </div>
                                        <div className="mb-5">
                                            <label className='form-label'>Email</label>
                                            <input className="form-control" type="email" name="email" placeholder='Введите email' value={formData.email} onChange={handleInputChange} />
                                            {errors.email && <span>{errors.email}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='mb-5'>
                                            <label className='form-label'>Должность</label>
                                            <input className="form-control" type="text" name="position" placeholder='Введите должность' value={formData.position} onChange={handleInputChange} />
                                            {errors.position && <span>{errors.position}</span>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Пароль</label>
                                            <input className="form-control" type="password" name="password" value={formData.password} onChange={handleInputChange} />
                                            {errors.password && <span>{errors.password}</span>}
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label'>Повторить пароль</label>
                                            <input className="form-control" type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} />
                                            {errors.confirmPassword && <span>{errors.confirmPassword}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="groups col-md-4">
                                    <div className="card border-light bg-transparent mb-3">
                                        <div className="card-header bg-transparent">
                                            <h5 className="mb-2">Группы доступов</h5>
                                        </div>
                                        <div className="card-body">
                                        <div>
                                            {groups.map(group => (
                                            <div key={group.id} className="col-md-12">
                                                <div className="form-check">
                                                <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        id={`group-${group.id}`} 
                                                        onChange={(e) => handleGroupInput(group.id, e.target.checked)}
                                                        />
                                                <label className="form-check-label" htmlFor={`group-${group.id}`}>
                                                    {group.name}
                                                </label>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary" type="submit">Сохранить</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CreateUserPage;