import React, { useState, useEffect, useRef, useCallback } from 'react';
import api from '../../api';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import ProtectedContent from '../ProtectedContent';
import { useAuth } from "../../AuthContext";
import SearchableSelect from '../payments/SearchableSelectB'; 
import { debounce } from 'lodash';


const Counterparties = () => {
    const navigate = useNavigate()
    const { permissions, user } = useAuth();
    const [counterparties, setCounterparties] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedCounterparties, setCheckedCounterparties] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const searchIDofelem = location.search.replace("?","");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = {
        name: '',
        bin_iin: '',
        code: '',
        company: '',
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [filters, setFilters] = useState({
        nameFilter: '',
        organFilter: '',
        iinbinFilter: '',
        archivedFilterNotArchive: false,
        archivedFilterActive: false
    });
    const handleFilterChange = (key, value) => {
        const newValue = key === 'organFilter' 
            ? (value ? value.id || value : '') 
            : (key.includes('archivedFilter') 
                ? (typeof value === 'object' ? value.target.checked : value)
                : value);
        
        setFilters(prev => ({
            ...prev,
            [key]: newValue
        }));
    };

    const fetchCounterparties = async (currentOffset = 0, isNewSearch = false) => {
        setLoading(true);
        try {
            const isFiltering = Object.values(filters).some(val => 
                val !== false && val !== ''
            ) || searchIDofelem;
            
            const filterParams = {
                id: searchIDofelem || undefined,
                name: filters.nameFilter ? filters.nameFilter.toLowerCase() : undefined,
                iinbin: filters.iinbinFilter ? filters.iinbinFilter.toLowerCase() : undefined,
                is_active: [
                    !filters.archivedFilterNotArchive, 
                    filters.archivedFilterActive
                ],
                company: filters.organFilter || undefined,
                limit,
                offset: currentOffset
            };
    
            const response = isFiltering 
                ? await api.post('/api/v1/bills/counterpartyfilter/', filterParams)
                : await api.get(`api/v1/bills/counterparty/?limit=${limit}&offset=${currentOffset}`);
    
            const newData = response.data.results || response.data;
            
            setCounterparties(prev => 
                isNewSearch 
                    ? newData 
                    : [...prev, ...newData.filter(newItem => 
                        !prev.some(existingItem => existingItem.id === newItem.id)
                    )]
            );
            
            setHasMore(newData.length === limit);
        } catch (error) {
            console.error('Ошибка загрузки контрагентов', error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error);
        }
    };
    const debouncedSearch = useCallback(
        debounce(() => {
            setOffset(0);
            setHasMore(true);
            fetchCounterparties(0, true);
        }, 300),
        [filters, searchIDofelem]
    );
    useEffect(() => {
        debouncedSearch();
        return () => debouncedSearch.cancel();
    }, [filters, searchIDofelem, debouncedSearch]);
    useEffect(() => {
        fetchCompanies();
    }, []); 
    
    useEffect(() => {
        setCounterparties([]);
        setOffset(0);
        setHasMore(true);
        fetchCounterparties();
    }, [location]);

    useEffect(() => {
        const loadMore = async () => {
            if (hasMore && !loading && offset > 0) {
                setLoading(true);
                try {
                    await fetchCounterparties();
                } catch (error) {
                    console.error('Error fetching data', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        
        loadMore();
    }, [offset, hasMore]);
    useEffect(() => {
        if (offset > 0) {
            fetchCounterparties(offset);
        }
    }, [offset]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchCounterpartiesById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post('/api/v1/bills/counterpartyfilter/', {
                        id:searchIDofelem,
                        is_active : [true,false]
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setCounterparties(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching caption by ID', error);
                }
            } else {
                setNameFilter('');
                setCounterparties([]);
                setOffset(0);
                setHasMore(true);
                fetchCounterparties();
            }
        };

        fetchCounterpartiesById();
    }, [searchIDofelem]);

    const handleUpdateCounterparties = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedCounterparties.map(id => 
                api.patch(`api/v1/bills/counterparty/${id}/update/`, { is_active: isActive })
            ));
            
            setFilters({
                nameFilter: '',
                organFilter: '',
                iinbinFilter: '',
                archivedFilterNotArchive: false,
                archivedFilterActive: false
            });
            
            setCounterparties([]);
            setOffset(0);
            setHasMore(true);
            setCheckedCounterparties([]);
            
            await fetchCounterparties(0, true);
        } catch (error) {
            console.error(`Ошибка ${isActive ? 'разархивации' : 'архивации'}`, error);
        } finally {
            setLoading(false);
        }
    };
    

    const handleCheckboxChange = (counterpartyId) => {
        setCheckedCounterparties(prevcounterparties => {
            if (prevcounterparties.includes(counterpartyId)) {
                return prevcounterparties.filter(id => id !== counterpartyId);
            } else {
                return [...prevcounterparties, counterpartyId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allcounterparties = counterparties.map(counterparty => counterparty.id);
            setCheckedCounterparties(allcounterparties);
        } else {
            setCheckedCounterparties([]);
        }
    };

    const allChecked = counterparties.length > 0 && counterparties.every(counterparty => checkedCounterparties.includes(counterparty.id));

    const handleReset = () => {
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();

    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ['name', 'bin_iin', 'company'];
        const errors = requiredFields.reduce((acc, key) => {
            if (!formData[key]) {
                if (key === 'company') {
                    acc[key] = 'Выберите значение';
                } else {
                    acc[key] = 'Заполните поле';
                }
            }
            return acc;
        }, {});
      
        if (!formData.bin_iin || formData.bin_iin.length !== 12) {
            errors.bin_iin = 'БИН/ИИН должен содержать ровно 12 цифр';
        }
    
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
        setLoading(true);
        try {
            if (isEditing) {
                const response = await api.patch(`/api/v1/bills/counterparty/${formData.id}/update/`, formData);
                console.log('Update Response:', response.data);
            } else {
                const response = await api.post('api/v1/bills/counterparty-create/', formData);
                console.log('Create Response:', response.data);
            }
            setCounterparties([]);
            setOffset(0);
            handleCloseModal();
            fetchCounterparties();
        } catch (error) {
            alert('Заполните все поля');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'bin_iin') {
            const cleanValue = value.replace(/\D/g, '');
            
            setFormData(prev => ({
                ...prev,
                [name]: cleanValue
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };
    
    useEffect(() => {
        const binIinValue = formData.bin_iin;
        
        if (binIinValue) {
            if (binIinValue.length !== 12) {
                setErrors(prev => ({
                    ...prev,
                    bin_iin: 'БИН/ИИН должен содержать 12 цифр'
                }));
            } else {
                setErrors(prev => ({ 
                    ...prev, 
                    bin_iin: '' 
                }));
            }
        } else {
            setErrors(prev => ({ 
                ...prev, 
                bin_iin: '' 
            }));
        }
    }, [formData.bin_iin]);
    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const handleRowClick = (counterparty) => {
        if (!permissions.includes('payment.change_counterparty') && !user.is_superuser) {
            return;
        }
        setIsEditing(true);
        setFormData({
            id: counterparty.id,
            name: counterparty.name,
            bin_iin: counterparty.bin_iin || '',
            code: counterparty.code || '',
            company: counterparty.company ? companies.find(company => company.id === counterparty.company)?.name : '',
        });
        setShowModal(true);
    };
    const handleCompanyChange = (value) => {
        setFormData(prev => ({
            ...prev,
            company: value
        }));
        if (errors.company) {
            setErrors(prev => ({ ...prev, company: '' }));
        }
    };

    return (
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Контрагенты</h3></div>
                                    <div className="mb-3 p-3 border rounded">
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование контрагента</label>
                                            <input
                                                type="text"
                                                placeholder="Введите контрагента"
                                                value={filters.nameFilter}
                                                onChange={(e) => handleFilterChange('nameFilter', e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div  className="col">
                                            <label className='form-label'>БИН/ИИН</label>
                                            <input
                                                type="text"
                                                placeholder="Введите БИН/ИИН"
                                                value={filters.iinbinFilter}
                                                onChange={(e) => handleFilterChange('iinbinFilter', e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Организация</label>
                                            <APIMultipleSearchableSelect
                                                endpoint="/api/v1/organization/search/"
                                                value={filters.organFilter}
                                                onChange={(value) => handleFilterChange('organFilter', value)}
                                                placeholder="Выберите организацию"
                                            />
                                        </div>
                                        <div className="col">
                                            <label>Архивный</label>
                                            <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="archivedCheckNotActive"
                                                checked={filters.archivedFilterNotArchive}
                                                onChange={(e) => handleFilterChange('archivedFilterNotArchive', e)}
                                            />
                                            <label className="form-check-label" htmlFor="archivedCheckNotActive">
                                                Да
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="archivedCheckActive"
                                                checked={filters.archivedFilterActive}
                                                onChange={(e) => handleFilterChange('archivedFilterActive', e)}
                                            />
                                            <label className="form-check-label" htmlFor="archivedCheckActive">
                                                Нет
                                            </label>
                                        </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <ProtectedContent perms={'payment.change_counterparty'}>
                                            <button 
                                                onClick={() => handleUpdateCounterparties(false)} 
                                                className="btn btn-secondary me-2"
                                                disabled={checkedCounterparties.length === 0}
                                            >
                                                Архивировать выбранные
                                            </button>
                                            <button 
                                                onClick={() => handleUpdateCounterparties(true)} 
                                                className="btn btn-success me-2"
                                                disabled={checkedCounterparties.length === 0}
                                            >
                                                Разархивировать выбранные
                                            </button>
                                        </ProtectedContent>
                                        <ProtectedContent perms={'payment.add_counterparty'}>
                                            <button className="btn btn-primary me-2" onClick={() => setShowModal(true)}>
                                                Добавить
                                            </button>
                                        </ProtectedContent>
                                        <button onClick={fetchCounterparties} className="btn btn-primary">Обновить</button>
                                        
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '55vh', overflowY: 'scroll' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input 
                                                    type="checkbox" 
                                                    checked={allChecked}
                                                    onChange={handleSelectAllChange}
                                                />
                                            </th>
                                            <th>Наименование контрагента</th>
                                            <th>БИН/ИИН</th>
                                            <th>Организация</th>
                                            <th>Архивный</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {counterparties.map((counterparty) => (
                                            <tr key={counterparty.name} onDoubleClick={() => handleRowClick(counterparty)}>
                                                <td>
                                                    <input 
                                                        type="checkbox"
                                                        checked={checkedCounterparties.includes(counterparty.id)}
                                                        onChange={() => handleCheckboxChange(counterparty.id)}
                                                    />
                                                </td>
                                                <td style={{ 
                                                    width: '300px', 
                                                    maxWidth: '300px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>
                                                    {counterparty.name}
                                                </td>
                                                <td>{counterparty.bin_iin}</td>
                                                <td style={{ 
                                                    width: '300px', 
                                                    maxWidth: '300px', 
                                                    whiteSpace: 'normal', 
                                                    wordWrap: 'break-word' 
                                                    }}>{counterparty.company ? companies.find(company => company.id === counterparty.company)?.name : ''}</td>
                                                <td>{counterparty.is_active ? 'Нет' : 'Да'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-center position-relative">
                            <h5 className="modal-title">
                                {isEditing ? 'Редактирование контрагента' : 'Создание контрагента'}
                            </h5>
                        </div>
                            <div className="modal-body">
                                <form className="bankaccount-form" onSubmit={handleSubmit}>
                                    <div className='form_input'>
                                            <div className="row g-1">
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Наименование контрагента*</label>
                                                    <input 
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                        type="text" 
                                                        name="name" 
                                                        value={formData.name}
                                                        placeholder='Введите наименование' 
                                                        onChange={handleChange}
                                                    />
                                                    {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                                                </div>
                                                <div className='col-md-12'>
                                                    <label className='form-label'>БИН/ИИН*</label>
                                                    <input 
                                                        className={`form-control ${errors.bin_iin ? 'is-invalid' : ''}`}
                                                        type="text" 
                                                        name="bin_iin" 
                                                        value={formData.bin_iin}
                                                        placeholder='Введите БИН/ИИН' 
                                                        onChange={handleChange}
                                                    />
                                                    {errors.bin_iin && <span className="invalid-feedback">{errors.bin_iin}</span>}
                                                </div>
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Организация*</label>
                                                    <SearchableSelect
                                                        endpoint="/api/v1/organization/"
                                                        value={formData.company || ''}
                                                        onChange={handleCompanyChange}
                                                        placeholder="Выберите организацию"
                                                        className={errors.company ? 'is-invalid' : ''}
                                                        isClearable={true}
                                                        isMulti={false}
                                                    />
                                                    {errors.company && (
                                                        <div className="invalid-feedback" style={{display: 'block'}}>
                                                            {errors.company}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer d-flex justify-content-center position-relative">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    Закрыть
                                </button>
                                <button className="btn btn-primary" onClick={handleSubmit}>
                                {isEditing ? 'Сохранить' : 'Создать'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Counterparties;
