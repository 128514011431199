// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.info-button {
    display: flex;
    justify-content: end;
}


`, "",{"version":3,"sources":["webpack://./src/styles/matrixPage.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,oBAAoB;AACxB","sourcesContent":["\r\n.info-button {\r\n    display: flex;\r\n    justify-content: end;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
