import { useEffect, useState } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../functions/UprData";


const PermissionGroup = ({ groupName, permissions, handlePermissionChange, groupPermissions }) => {
    return (
      <div className="card border-light bg-transparent mb-3">
        <div className="card-header bg-transparent">
            <h5 className="mb-2">{groupName}</h5>
        </div>
        <div className="card-body">
          <div>
            {permissions.map(permission => (
              <div key={permission.id} className="col-md-12">
                <div className="form-check">
                  <input 
                        className="form-check-input" 
                        type="checkbox" 
                        id={`permission-${permission.id}`} 
                        checked={groupPermissions.includes(permission.id)}
                        onChange={(e) => handlePermissionChange(permission.id, e.target.checked)}
                        />
                  <label className="form-check-label" htmlFor={`permission-${permission.id}`}>
                    {permission.name}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

const CreateGroup = () => {

    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState({
        "name": '',
        "permissions": []
    });
    const [permissions, setPermissions] = useState([]);
    const navigate = useNavigate()
    const [errors, setErrors] = useState({});
    const [selectAll, setSelectAll] = useState(false);

    const handleInputChange = (e) => {
        setGroup({ ...group, [e.target.name]: e.target.value });
    }

    const handlePermissionChange = (permissionId, isChecked) => {
        setGroup(prevGroup => {
            let updatedPermissions;
            if (isChecked) {
                // Add permission to the array
                updatedPermissions = [...prevGroup.permissions, permissionId];
            } else {
                // Remove permission from the array
                updatedPermissions = prevGroup.permissions.filter(id => id !== permissionId);
            }
            return {
                ...prevGroup,
                permissions: updatedPermissions
            };
        });
    };

    const fetchPermissions = useFetchData('/api/v1/permissions/', setPermissions);

    useEffect(() => {
        fetchPermissions();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { name, permissions } = group;
        const errors = {};
        if (!name) {
            errors.name = 'Group name is required';
        }
        if (permissions.length === 0) {
            errors.permissions = 'Permission is required';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        try {
            setLoading(true);
            const response = await api.post('/api/v1/groups/create/', group);
            setLoading(false);
            navigate('/finmodel')
        } catch (error) {
            console.error('Create group failed:', error.response.data);
            // Handle error (e.g., show error message)
        } finally {
            setLoading(false);
        }
    }    
    
    const handleSelectAllChange = (isChecked) => {
        setSelectAll(isChecked);
        if (isChecked) {
            const allPermissionIds = Object.values(permissions).flat().map(permission => permission.id);
            setGroup(prevGroup => ({
                ...prevGroup,
                permissions: allPermissionIds
            }));
        } else {
            setGroup(prevGroup => ({
                ...prevGroup,
                permissions: []
            }));
        }
    };

    useEffect(() => {
        const allPermissionIds = Object.values(permissions).flat().map(permission => permission.id);
        if (group.permissions.length === allPermissionIds.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [group.permissions, permissions]);

    return (
        <>
        <div className="container-fluid">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col">
                    <div className="create-group-header mt-3">
                        <h2>Создание группы</h2>
                    </div>
                    <div className="create-group-content mt-4">
                        <form className="group-form" onSubmit={handleSubmit}>
                            <div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <div>
                                            <label className='form-label'>Наименование</label>
                                            <input className="form-control" type="text" name="name" placeholder='Введите название' value={group.name} onChange={handleInputChange} />
                                            {errors.name && <span>{errors.name}</span>}
                                        </div>
                                    </div>
                                </div>
                                <h4>Группы доступа</h4>
                                <div className="form-check mb-3">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="selectAll" 
                                        checked={selectAll}
                                        onChange={(e) => handleSelectAllChange(e.target.checked)}
                                        />
                                    <label className="form-check-label" htmlFor="selectAll">
                                    Выбрать все
                                    </label>
                                </div>
                                <div className="row row-cols-5">
                                    {Object.keys(permissions).map(groupName => (
                                    <div key={groupName} className="col">
                                        <PermissionGroup
                                        groupName={groupName}
                                        permissions={permissions[groupName]}
                                        handlePermissionChange={handlePermissionChange}
                                        groupPermissions={group.permissions}
                                        />
                                    </div>
                                    ))}
                                </div>
                                {errors.permissions && <span>{errors.permissions}</span>}
                            </div>
                            <button className="btn btn-primary" type="submit">Сохранить</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default CreateGroup;