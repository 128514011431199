import api from '../../api';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import { useState, useEffect, useRef, useCallback  } from 'react';
import ReactLoading from 'react-loading';
import APIMultipleSearchableSelect from '../payments/SearchableTableMultiSelect'; 
import { useLocation, useNavigate} from 'react-router-dom';
import ProtectedContent from '../ProtectedContent';
import { useAuth } from "../../AuthContext";
import SearchableSelect from '../payments/SearchableSelect'; 


const Projects = () => {
    const { permissions, user } = useAuth();
    const [projects, setProjects] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const limit = 25;
    const [checkedProjects, setCheckedProjects] = useState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [finModelFilter, setSelectedFinModel] = useState('');
    const [archivedFilterYES, setArchivedFilterYES] = useState(false);
    const [archivedFilterNO, setArchivedFilterNO] = useState(false);
    const [startDateFilterOne, setStartOneDateFilter] = useState('');
    const [startDateFilterTwo, setStartTwoDateFilter] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const searchIDofelem = location.search.replace("?","");
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const initialFormData = {
        name: '',
        full_name: '',
        description: '',
        finmodel: '',
        company: '',
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState({});

    const fetchProjects = async () => {
        try {
            if (searchIDofelem){
                const response = await api.post('/api/v1/organization/projectsfilter/', {
                    id:searchIDofelem,
                    is_active : [true,false]
                });
                setProjects(response.data);
            }else{
                const response = await api.get(`/api/v1/organization/projects/?limit=${limit}&offset=${offset}`);
            setProjects(prevProjects => {
                const newProject = response.data.results.filter(newProject => 
                    !prevProjects.some(prevProjects => prevProjects.id === newProject.id)
                );
                return [...prevProjects, ...newProject];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
            }
        } catch (error) {
            console.error('Error fetching organizations', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchCompanies = async () => {
        try {
            const response = await api.get('/api/v1/organization/');
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies', error)
        }
    }
    const fetchGroups = async () => {
        try {
            const response = await api.get('/api/v1/organization/groups/');
            setGroups(response.data);
        } catch (error) {
            console.error('Error fetching bank accounts', error)
        }
    }

    useEffect(() => {
        setProjects([]); // Clear existing payments
        setCompanies([]);
        setOffset(0); // Reset offset
        setHasMore(true);
        fetchProjects();
    }, [location]);

   

    useEffect(() => async () =>{
        setLoading(true);
        try {
            if (hasMore) {
                fetchProjects(offset);
            }
            await Promise.all([
                fetchGroups(),
                fetchCompanies(),
            ]);
        } catch (error) {
            console.error('Error fetching data', error);
        } finally {
            setLoading(false);
        }
    }, [offset, hasMore, location]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        const fetchProjectsById = async () => {
            if (searchIDofelem) {
                try {
                    const response = await api.post('/api/v1/organization/projectsfilter/', {
                        id:searchIDofelem,
                        is_active : [true,false]
                    });
                    if (response.data.length > 0) {
                        setNameFilter(response.data[0].name);
                        setProjects(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching caption by ID', error);
                }
            } else {
                setNameFilter('');
                setProjects([]);
                setOffset(0);
                setHasMore(true);
                fetchProjects();
            }
        };

        fetchProjectsById();
    }, [searchIDofelem]);

    const handleUpdateProjects = async (isActive) => {
        setLoading(true);
        try {
            await Promise.all(checkedProjects.map(id => 
                api.patch(`/api/v1/organization/projects/${id}/update/`, { is_active: isActive })
            ));
            // Refresh the list after updating
            setProjects([]);
            fetchProjects();
            // setCompanies([]);
            setOffset(0);
            setHasMore(true);
            setCheckedProjects([])
        } catch (error) {
            console.error(`Error ${isActive ? 'unarchiving' : 'archiving'} Projects`, error);
        } finally {
            setLoading(false);
        }
    };

    const handleArchiveProjects = () => handleUpdateProjects(false);
    const handleUnarchiveProjects = () => handleUpdateProjects(true);
    

    const handleCheckboxChange = (prjId) => {
        setCheckedProjects(prevPrjs => {
            if (prevPrjs.includes(prjId)) {
                return prevPrjs.filter(id => id !== prjId);
            } else {
                return [...prevPrjs, prjId];
            }
        });
    };

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allPrjs = projects.map(project => project.id);
            setCheckedProjects(allPrjs);
        } else {
            setCheckedProjects([]);
        }
    };

    // if (loading) {
    //     return (
    //         <div className="position-absolute top-50 start-50 translate-middle">
    //             <ReactLoading type="spin" color="#0000FF" height={50} width={50} />
    //         </div>);
    // }
    const allChecked = projects.length > 0 && projects.every(project => checkedProjects.includes(project.id));

    const handleReset = () => {
        const search = location.search
        navigate(location.search.replace(search,'')); 
        window.location.reload();
    };
    const handleSearch = async () => {
        try {
            console.log(nameFilter,archivedFilterYES,archivedFilterNO,finModelFilter,startDateFilterOne,startDateFilterTwo)
            const response = await api.post('/api/v1/organization/projectsfilter/', {
                name: nameFilter.toLowerCase(), 
                is_active : [!archivedFilterYES,archivedFilterNO], 
                finmodel : finModelFilter,
                startDateOne:startDateFilterOne,
                startDateTwo:startDateFilterTwo,
               
            });
            console.log('project fetched:', response.data); 
            setProjects(response.data);
        } catch (error) {
            console.error('Error fetching project:', error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const requiredFields = ['name', 'full_name', 'company', 'finmodel'];
        const errors = requiredFields.reduce((acc, key) => {
            if (!formData[key]) {
                if (key === 'finmodel' || key === 'company') {
                    acc[key] = 'Выберите значение';
                } else {
                    acc[key] = 'Заполните поле';
                }
            }
            return acc;
        }, {});
      
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
          }
        setLoading(true);
        try {
            if (isEditing) {
                const response = await api.patch(`/api/v1/organization/projects/${formData.id}/update/`, formData);
                console.log('Update Response:', response.data);
            } else {
                const response = await api.post('/api/v1/organization/projects/create/', formData);
                console.log('Create Response:', response.data);
            }
            setProjects([]);
            setOffset(0);
            handleCloseModal();
            fetchProjects();
        } catch (error) {
            alert('Заполните все поля');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };
    const resetForm = () => {
        setFormData(initialFormData);
        setIsEditing(false);
    };

    const handleCloseModal = () => {
        resetForm();
        setShowModal(false);
        setErrors({});
    };

    const handleRowClick = (project) => {
        if (!permissions.includes('organization.change_project') && !user.is_superuser) {
            return;
        };
        setIsEditing(true);
        setFormData({
            id: project.id,
            name: project.name,
            full_name: project.full_name || '',
            description: project.description || '',
            finmodel: project.finmodel ? groups.find(group => group.id === project.finmodel)?.name : '',
            company: project.company ? companies.find(company => company.id === project.company)?.name : '',
        });
        setShowModal(true);
    };
    const handleFinmodelChange = (value) => {
        setFormData(prev => ({
            ...prev,
            finmodel: value
        }));
        if (errors.finmodel) {
            setErrors(prev => ({ ...prev, finmodel: '' }));
        }
    };
    const handleCompanyChange = (value) => {
        setFormData(prev => ({
            ...prev,
            company: value
        }));
        if (errors.company) {
            setErrors(prev => ({ ...prev, company: '' }));
        }
    };
    

    return (
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h3>Проекты</h3></div>

                                    <div className="mb-3 p-3 border rounded" style={{display:'block'}} >
                                    <h5>Фильтры:</h5>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-label'>Наименование проекта</label>
                                            <input
                                                type="text"
                                                placeholder="Введите наименование проекта"
                                                value={nameFilter}
                                                onChange={e => setNameFilter(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Родительская группа</label>
                                        <APIMultipleSearchableSelect
                                            endpoint="/api/v1/organization/groups/search/"
                                            value={finModelFilter}
                                            onChange={(value) => setSelectedFinModel(value)}
                                            placeholder="Выберите группу"
                                        />
                                        </div>
                                        <div className="col">
                                            <label className='form-label'>Дата создания</label>
                                            <div className="d-flex">
                                            <span  style={{marginTop:'7px',marginRight:'7px'}}>с</span>
                                                <input
                                                type="date"
                                                value={startDateFilterOne}
                                                onChange={e => setStartOneDateFilter(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                                <span className="mx-2" style={{marginTop:'7px'}}>—</span><span  style={{margin:'7px',marginRight:'7px'}}>до</span>
                                                <input
                                                type="date"
                                                value={startDateFilterTwo}
                                                onChange={e => setStartTwoDateFilter(e.target.value)}
                                                className="form-control form-control-sm"
                                            />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <label >Архивный</label>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckYES"
                                                    checked={archivedFilterYES}
                                                    onChange={e => setArchivedFilterYES(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckYES">
                                                    Да
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="archivedCheckNO"
                                                    checked={archivedFilterNO}
                                                    onChange={e => setArchivedFilterNO(e.target.checked)}
                                                />
                                                <label className="form-check-label" htmlFor="archivedCheckNO">
                                                    Нет
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col align-self-end">
                                            <button
                                                onClick={handleSearch}
                                                className="btn btn-primary mt-3"
                                            >
                                                Поиск
                                            </button>
                                            <button
                                                onClick={handleReset}
                                                className="btn btn-outline-secondary mt-3"
                                            >
                                                Сбросить
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <ProtectedContent perms={'organization.change_project'}>
                                            <button 
                                                onClick={handleArchiveProjects} 
                                                className="btn btn-secondary me-2"
                                                disabled={checkedProjects.length === 0}
                                            >
                                                Архивировать выбранные
                                            </button>
                                            <button 
                                                onClick={handleUnarchiveProjects} 
                                                className="btn btn-success me-2"
                                                disabled={checkedProjects.length === 0}
                                            >
                                                Разархивировать выбранные
                                            </button>
                                        </ProtectedContent>
                                        <ProtectedContent perms={'organization.add_project'}>
                                            <button className="btn btn-primary me-2" onClick={() => setShowModal(true)}>
                                                Добавить
                                            </button>
                                        </ProtectedContent>
                                        <button onClick={fetchProjects} className="btn btn-primary">Обновить</button>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '75vh', overflowY: 'scroll' }}>
                                <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>
                                            <input 
                                                type="checkbox" 
                                                checked={allChecked}
                                                onChange={handleSelectAllChange}
                                            />
                                        </th>
                                        <th>Наименование проекта</th>
                                        <th>Родительская группа</th>
                                        <th>Организация</th>
                                        <th>Дата создания</th>
                                        <th>Архивный</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projects.map((project) => (
                                        <tr key={project.name} onDoubleClick={() => handleRowClick(project)}>
                                            <td>
                                                    <input 
                                                        type="checkbox"
                                                        checked={checkedProjects.includes(project.id)}
                                                        onChange={() => handleCheckboxChange(project.id)}
                                                    />
                                            </td>
                                            <td>{project.name}</td>
                                            <td>{project.finmodel ? groups.find(group => group.id === project.finmodel)?.name : ''}</td>
                                            <td>{project.company ? companies.find(company => company.id === project.company)?.name : ''}</td>
                                            <td>
                                                {new Date(project.created).toLocaleString('ru-RU', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                timeZone: 'Asia/Almaty'
                                                })}
                                            </td>
                                            <td>{project.is_active ? 'Нет' : 'Да'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                </table>
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal ${showModal ? 'd-block' : 'd-none'}`} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-center position-relative">
                            <h5 className="modal-title">
                                {isEditing ? 'Редактирование проекта' : 'Создание проекта'}
                            </h5>
                        </div>
                            <div className="modal-body">
                                <form className="bankaccount-form" onSubmit={handleSubmit}>
                                    <div className='form_input'>
                                            <div className="row g-1">
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Наименование проекта*</label>
                                                    <input 
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                        type="text" 
                                                        name="name" 
                                                        value={formData.name}
                                                        placeholder='Введите наименование' 
                                                        onChange={handleChange}
                                                    />
                                                    {errors.name && <span className="invalid-feedback">{errors.name}</span>}
                                                </div>
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Полное наименование проекта*</label>
                                                    <input 
                                                        className={`form-control ${errors.full_name ? 'is-invalid' : ''}`}
                                                        type="text" 
                                                        name="full_name"
                                                        value={formData.full_name} 
                                                        onChange={handleChange} 
                                                        placeholder='Введите полное наименование'
                                                    />
                                                    {errors.full_name && <span className="invalid-feedback">{errors.full_name}</span>}
                                                </div>
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Родительская группа в фин.моделе*</label>
                                                    <SearchableSelect
                                                        endpoint="/api/v1/organization/groups/search/"
                                                        value={formData.finmodel || ''}
                                                        onChange={handleFinmodelChange}
                                                        placeholder="Выберите тип статьи"
                                                        isClearable={true}
                                                        isMulti={false}
                                                    />
                                                    {errors.finmodel && (
                                                        <div className="invalid-feedback" style={{display: 'block'}}>
                                                            {errors.finmodel}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-md-12'>
                                                    <label className='form-label'>Организация*</label>
                                                    <SearchableSelect
                                                        endpoint="/api/v1/organization/"
                                                        value={formData.company || ''}
                                                        onChange={handleCompanyChange}
                                                        placeholder="Выберите организацию"
                                                        className={errors.company ? 'is-invalid' : ''}
                                                        isClearable={true}
                                                        isMulti={false}
                                                    />
                                                    {errors.company && (
                                                        <div className="invalid-feedback" style={{display: 'block'}}>
                                                            {errors.company}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-12">
                                                    <label className='form-label'>Описание</label>
                                                    <input 
                                                        className="form-control" 
                                                        type="text" 
                                                        name="description"
                                                        value={formData.description} 
                                                        onChange={handleChange} 
                                                        placeholder='Введите описание'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer d-flex justify-content-center position-relative">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                                    Закрыть
                                </button>
                                <button className="btn btn-primary" onClick={handleSubmit}>
                                {isEditing ? 'Сохранить' : 'Создать'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;
