import api from "../api";

const useFetchData = (endpoint, setter) => {
    const fetchData = async () => {
        try {
            const response = await api.get(endpoint);
            setter(response.data)
        } catch (error) {
            console.error(`Error fetching data from ${endpoint}`, error);
        }
    };

    return fetchData;
};

export default useFetchData;