import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from "../../api";

const ModalOfProjectList = ({isOpen, hideModal, onProjectSelect, month}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searching, setSearching] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [step, setStep] = useState(month ? 'date' : 'project');

    useEffect(() => {
        const searchProjects = async () => {
            if (searchTerm.length > 0) {
                setSearching(true);
                try {
                    const response = await api.get(`api/v1/organization/projects/search/?search=${searchTerm}`);
                    setSearchResults(response.data);
                } catch (error) {
                    console.error('Error searching projects:', error);
                    setSearchResults([]);
                } finally {
                    setSearching(false);
                }
            } else {
                setSearchResults([]);
            }
        };

        const debounce = setTimeout(() => {
            searchProjects();
        }, 300);

        return () => clearTimeout(debounce);
    }, [searchTerm]);

    const handleProjectSelect = (project) => {
        setSelectedProject(project);
        if (month) {
            onProjectSelect(project.id, selectedDate);
            hideModal();
        } else {
            onProjectSelect(project.id);
            hideModal();
        }
    };

    const handleDateSelect = (date) => {
        setSelectedDate(date);
    };

    const handleNextStep = () => {
        if (selectedDate) {
            setStep('project');
        }
    };

    const renderDateSelection = () => (
        <>
            <h5 className="mb-3">Выберите дату</h5>
            <input
                type='date'
                onChange={(e) => handleDateSelect(e.target.value)}
                value={selectedDate || ''}
                className="form-control"
            />
        </>
    );

    const renderProjectSelection = () => (
        <>
            <input
                type="text"
                className="form-control mb-3"
                placeholder="Поиск проектов..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searching && <div className="text-center">Ищем...</div>}
            {!searching && searchResults.length > 0 && (
                <ul className="list-group">
                    {searchResults.map(project => (
                        <li 
                            key={project.id} 
                            className="list-group-item list-group-item-action"
                            onClick={() => handleProjectSelect(project)}
                            style={{cursor: 'pointer'}}
                        >
                            {project.name}
                        </li>
                    ))}
                </ul>
            )}
            {!searching && searchTerm && searchResults.length === 0 && (
                <div className="text-center">Не найдено</div>
            )}
        </>
    );

    return (
        <Modal show={isOpen} onHide={hideModal} dialogClassName="modal-dialog-centered modal-dialog-scrollable">
            <Modal.Header className='d-flex'>
                <Modal.Title>Создать матрицу</Modal.Title>
                <i 
                    className='fs-6 ms-auto bi bi-x-circle'
                    onClick={hideModal}
                    style={{cursor: 'pointer'}}
                ></i>
            </Modal.Header>
            <Modal.Body>
                {month ? (
                    step === 'date' ? renderDateSelection() : renderProjectSelection()
                ) : (
                    renderProjectSelection()
                )}
            </Modal.Body>
            {month && step === 'date' && (
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>
                        Отмена
                    </Button>
                    <Button variant="primary" onClick={handleNextStep} disabled={!selectedDate}>
                        Далее
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default ModalOfProjectList;