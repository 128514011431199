// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchable-select {
    position: relative;
    
}

.select-dropdown {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

}


.select-dropdown .list-group-item {
    cursor: pointer;    

}

.select-dropdown .list-group-item:hover {
    background-color: #f8f9fa;
}

.red-x {
    margin-left: 10px;
    color: red;
    font-weight: bold;
}

.disabled {
    pointer-events: none;
    opacity: 1;
    color: #6c757d;
    background-color: #e9ecef;
  }
`, "",{"version":3,"sources":["webpack://./src/styles/searchSelect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;AAEtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,sBAAsB;;AAE1B;;;AAGA;IACI,eAAe;;AAEnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,UAAU;IACV,cAAc;IACd,yBAAyB;EAC3B","sourcesContent":[".searchable-select {\r\n    position: relative;\r\n    \r\n}\r\n\r\n.select-dropdown {\r\n    position: absolute;\r\n    width: 100%;\r\n    max-height: 200px;\r\n    overflow-y: auto;\r\n    z-index: 1000;\r\n    background-color: white;\r\n    border: 1px solid #ced4da;\r\n    border-radius: 0.25rem;\r\n\r\n}\r\n\r\n\r\n.select-dropdown .list-group-item {\r\n    cursor: pointer;    \r\n\r\n}\r\n\r\n.select-dropdown .list-group-item:hover {\r\n    background-color: #f8f9fa;\r\n}\r\n\r\n.red-x {\r\n    margin-left: 10px;\r\n    color: red;\r\n    font-weight: bold;\r\n}\r\n\r\n.disabled {\r\n    pointer-events: none;\r\n    opacity: 1;\r\n    color: #6c757d;\r\n    background-color: #e9ecef;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
