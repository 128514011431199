import { useState, useEffect, useRef, useCallback  } from 'react';
import PaymentsTable from './PaymentsTable';
import api from '../../api';
import { Link } from 'react-router-dom';
import SideBar from '../SideBar';
import Header from "../Header";
import { Modal } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import ProtectedContent from '../ProtectedContent';
import CreateRuleModal from './CreateRuleModal';
import EditRuleModal from './EditRuleModal';

const PaymentManagement = () => {
    const [payments, setPayments] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const [openRuleModal, setOpenRuleModal] = useState(false);
    const [rules, setRules] = useState([]);
    const [selectedRuleId, setSelectedRuleId] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const containerRef = useRef(null);
    const limit = 25;

    const showModal = async() => {
        try {
            if (rules.length === 0) {
                setLoading(true);
                const response = await api.get('api/v1/bills/auto-spacing-rules/');
                if (response.status === 200) {
                    setRules(response.data);
            }}
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setIsOpen(true);
        }
    };

    const handleRuleActivityChange = async (rule) => {
        try {
            const response = await api.patch(`api/v1/bills/auto-spacing-rule/${rule.id}/action/`, {'is_active': !rule.is_active});
            if (response.status === 200) {
                setRules((prevRules) => 
                    prevRules.map((b) => 
                        b.id === rule.id ? { ...b, is_active: !b.is_active } : b
                    )
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleDeleteRule = async (rule) => {
        try {
            const response = await api.patch(`api/v1/bills/auto-spacing-rule/${rule.id}/action/`, {'is_deleted': true});
            if (response.status === 200) {
                setRules((prevRules) => 
                    prevRules.filter((b) => b.id !== rule.id)
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleRuleDoubleClick = (ruleId) => {
        setSelectedRuleId(ruleId);
        setShowEditModal(true);
    };
  
    const hideModal = () => {
      setIsOpen(false);
    };

    const handleOpenModal = () => {
        setOpenRuleModal(true);
    }

    const fetchPayments = async (offset) => {
        setLoading(true);
        try {
            const response = await api.get(`api/v1/bills/payments/?limit=${limit}&offset=${offset}`);
            setPayments(prevPayments => {
                const newPayments = response.data.results.filter(newPayment => 
                    !prevPayments.some(prevPayment => prevPayment.id === newPayment.id)
                );
                return [...prevPayments, ...newPayments];
            });
            if (!response.data.next) {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching payments', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setPayments([]); // Clear existing payments
        setOffset(0); // Reset offset
        setHasMore(true);
    }, []);

    useEffect(() => {
        if (hasMore) {
            fetchPayments(offset);
        }
    }, [offset, hasMore]);


    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
                setOffset(prevOffset => prevOffset + limit);
            }
        }
    }, [hasMore, loading]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);


    return (
        <div className="container-fluid" translate="no">
            <Header/>
            <div className="row flex-nowrap">
                <SideBar/>
                <div className="col py-3">
                    <div className="payment-management container-fluid">
                        <div className="row">
                            <div className="col-md">
                                <h3>Платежи</h3>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <div>
                                        <ProtectedContent perms={"payment.add_payment"}>
                                            <Link to='/create-payment' className='btn btn-success me-2'>Добавить платеж</Link>
                                        </ProtectedContent>
                                        <button onClick={() => setOffset(0)} className="btn btn-primary">Обновить</button>
                                        <ProtectedContent perms={"payment.view_autospacinggroup"}>
                                            <button className="btn btn-info ms-2" onClick={showModal}>Правила авторазнесения</button>
                                        </ProtectedContent>
                                    </div>
                                </div>
                                <div ref={containerRef} className="table-responsive" style={{ height: '75vh', overflowY: 'scroll' }}>
                                    <PaymentsTable payments={payments} />
                                </div>
                                {loading && 
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <ReactLoading type="spin" color="#0000FF" height={30} width={30} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={isOpen} onHide={hideModal} dialogClassName={"modal-dialog-centered modal-dialog-scrollable"}>
                <Modal.Header>
                    <Modal.Title>Правила авторазнесения документов</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className='mt-3 mb-4'>
                            <p>Текущие правила</p>
                            <div>
                                <table className='table table-borderless table-sm'>
                                    <tbody>
                                        {rules.map((rule) => (
                                            <tr 
                                                key={rule.id} 
                                                onDoubleClick={() => handleRuleDoubleClick(rule.id)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <td className='w-10'>
                                                    <div className='form-switch'>
                                                        <input 
                                                            class="form-check-input" 
                                                            type="checkbox" 
                                                            role="switch"
                                                            checked={rule.is_active}
                                                            onChange={() => {handleRuleActivityChange(rule)}}/>
                                                    </div>
                                                </td>
                                                <td className='text-start'>{rule?.name}</td>
                                                <td><i className='bi bi-trash3' onClick={() => {handleDeleteRule(rule)}}></i></td>
                                            </tr>
                                        ))}                                        
                                    </tbody>
                                </table>
                            </div>
                            <button className='btn btn-success btn-sm mt-3' onClick={handleOpenModal}>+ Добавить новое правило</button>
                        </div>
                    </Modal.Body>
                {/* <Modal.Footer>
                <button onClick={hideModal}>Cancel</button>
                <button>Save</button>
                </Modal.Footer> */}
            </Modal>
            <CreateRuleModal 
                showModal={openRuleModal} 
                setShowModal={setOpenRuleModal} 
                rules={rules} 
                setRules={setRules}
            />
            <EditRuleModal 
                showModal={showEditModal}
                setShowModal={setShowEditModal}
                rules={rules}
                setRules={setRules}
                ruleId={selectedRuleId}
            />
        </div>
        
    );
};

export default PaymentManagement;
