import { useNavigate } from "react-router-dom";
import '../../styles/paymentStyle.css'
import { useAuth } from "../../AuthContext";

const PaymentsTable = ({ payments }) => {
    const { permissions, user } = useAuth();
    const navigate = useNavigate()
    const handleRowClick = (payment) => {
        if (!permissions.includes('payment.change_payment') && !user.is_superuser) {
            return;
        }
        navigate(`/edit-payment/${payment.id}`)
    }

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
    
        return `${day}.${month}.${year}`;
    };

    
    const getStatusDisplay = (payment) => {
        const { is_spaced, is_auto_spaced } = payment;
        const bgColor = is_spaced ? '#75EA27' : '#FB7E24';
        const showA = is_auto_spaced;

        return (
            <div className="position-relative">
                <div 
                    className="d-flex align-items-center justify-content-center rounded-circle"
                    style={{ 
                        backgroundColor: bgColor,
                        width: '16px',
                        height: '16px',
                        color: 'white',
                        margin: 'auto'
                    }}
                >
                    {showA && (
                        <span className="fw-bold" style={{ fontSize: '10px' }}>
                            A
                        </span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <table className="table table-striped">
            <thead className="table-light">
                <tr>
                    <th>Статус</th>
                    <th>Документ</th>
                    <th>Дата платежа</th>
                    <th>Организация</th>
                    <th>Сумма</th>
                    <th>Контрагент</th>
                    <th>Назначение платежа</th>
                    <th>Банковский счет</th>
                    <th>Комментарий</th>
                    <th>НДС</th>
                </tr>
            </thead>
            <tbody>
                {payments.map((payment) => (
                    <tr key={payment.id} onDoubleClick={() => handleRowClick(payment)}>
                        <td>{getStatusDisplay(payment)}</td>
                        <td>{payment.document_type ? payment.document_type.name : ''}</td>
                        <td>{formatDate(payment.date)}</td>
                        <td>{payment.company ? payment.company.name : ''}</td>
                        <td>{new Intl.NumberFormat('ru-RU').format(payment.total_sum)}</td>
                        <td>{payment.counterparty ? payment.counterparty.name : ''}</td>
                        <td>{payment.purpose_of_payment}</td>
                        <td>{payment.company_account ? payment.company_account.name : ''}</td>
                        <td>{payment.comment}</td>
                        <td>{payment.commission_sum}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default PaymentsTable;
